import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import axios from 'axios';
import { toast } from 'react-toastify';
import Terms from '../Button/Terms';

const ConfirmationAccount = (props: any): JSX.Element => {

    const { userContext, setUserContext } = useUser();
    const [userEmail, setUserEmail] = useState<any>("");
    const [isLoading, setIsLoading] = useState<any>(false);
    const [resend, setResend] = useState<any>(false);

    const handleChange = (e: any) => {
        setUserEmail(e.target.value);
    };

    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const linkMigo = async () => {
        toast.info('Se está iniciando proceso de compra');
        const getURLPay = await axios({
            method: "post",
            url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/pay`,
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
            },
            data: {
                userId: userContext.id,
                quantity: userContext.quantity,
                total: userContext.total
            },
        });
        if(getURLPay.status === 200 && getURLPay.data.url){
            window.open(getURLPay.data.url, '_self');
        }
        else toast.error("No se pudo realizar la conexion con el sitio de pago");
        
    };

    useEffect( () => {
        
    },[]);

    return (
        <div className="w-full py-6 relative">
            <div className="text-sm mb-2 z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>
            <div className="w-full px-6 sm:px-10">
                <div className="my-4 text-center">
                    <p className="text-lg font-small text-unete-grey">
                    Al generar el link de pago se conectará automáticamente a la plataforma Migo donde podra finalizar la compra de los tickets.
                    </p>
                    <br/>
                    <p className='text-center'><span onClick={() => {linkMigo();}} className='font-medium underline cursor-pointer text-unete-blue-n'>GENERAR LINK DE PAGO</span></p>
                    <br/>
                    <p className="text-lg font-small text-unete-grey">
                    Al finalizar el pago será redirigido a una página donde se confirmara la compra y registro de sus tickets.
                    </p>
                    <br />
                    <p className="text-lg font-small text-unete-grey">
                    <b>Si ya no desea continuar con el pago por favor cerrar este mensaje.</b>
                    </p>
                </div>
            </div>      
        </div>
    );
}

export default ConfirmationAccount;