import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../context/cart-context';

//Assets
import logoPepsi from '../../assets/img/pepsi.png';
import logoGTC from '../../assets/img/banco.png';
import logoCampero from '../../assets/img/campero.png';
import logoTigo from '../../assets/img/tigo.png';
import logoUnete from '../../assets/img/unete-footer.png'
import iconX from '../../assets/img/icon-x-blue.png';
import modalHeader from '../../assets/img/we_modalHeader.png';
import modalBack from '../../assets/img/we_modalBack.png';

//Components
import SingUp from './SingUp';
import DataTicket from './DataTicket';
import UserTickets from './UserTickets';
import LogIn from './LogIn';
import Thanks from './Thanks';
import Congratulations from './Congratulations';
import Options from './Options';
import Start from './Start';
import ConfirmationAccount from './ConfirmAccount';
import AnonymousSingUp from './AnonymousSingUp';
import SelectTicket from './SelectTicket';
import PasswordRecovery from './PasswordRecovery';
import ChangePassword from './ChangePassword';
import Checkout from './Checkout';
import PaymentResult from './PaymentResult';
import TableWinners from './TableWinners';
import PaymentWait from './PaymentWait';
import PaymentExpired from './PaymentExpired';

const payment = require('payment');

const Modals = (props: any): JSX.Element => {

    const { userContext } = useUser();
    const history = useHistory();
    const [navigate, setNavigate] = useState('');
    const [select, setSelect] = useState('');
    const [body, setBody] = useState({
        frequency: "S",
        numberoOfPayments: 1,
        ticket: "type1",
        card: "",
        name: "",
        expMonth: "",
        expYear: "",
        expDate: "",
        cvv: "",
        html: "",
        customValue: 0,
        isRecurrent: false,
    });

    const ticketsValues: any = {
        type1: 20,
        type2: 40,
        type3: 60,
        type4: 80,
        type5: 100,
    };
    
    const ticketsQuantity: any = {
        type1: 1,
        type2: 2,
        type3: 3,
        type4: 4,
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setBody({ ...body, [name]: value });
    };

    useEffect( () => {
        setBody({ ...body, ticket: props.selected });
        setNavigate(props.section);
        setSelect(props.optionSelected)
    }, [props.selected]);

    const onCloseChange = () => {
        history.push('/');
        props.onClose()
    }

    const updateModal = (navigate:string, select:string = '') => {
        setNavigate(navigate);
        if(select !== '') setSelect(select);
    }

    let JSXElement = <p></p>;

    switch (navigate) {
        case "start":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <Start navigate={updateModal}/>
            ) : (
                <Options navigate={updateModal}/>
            );
            break;

        case "options":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <Start navigate={updateModal}/>
            ) : (
                <Options navigate={updateModal}/>
            );
            break;

        case "login":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <LogIn navigate={updateModal} select={select}/>
            ) : (
                <Options navigate={updateModal}/>
            );
            break;

        case "sing-up":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <SingUp navigate={updateModal} select={select}/>
            ) : (
                <Options navigate={updateModal}/>
            );
            break;

        case "anonymousRegister":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <AnonymousSingUp navigate={updateModal} select={select}/>
            ) : (
                <Options navigate={updateModal}/>
            );
            break;

        case "selectTicket":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <Start navigate={updateModal}/>
            ) : (
                <SelectTicket
                    values={body}
                    quantity={ticketsQuantity[body.ticket]}
                    handleChange={handleChange}
                    navigate={updateModal}
                />
            );
            break;

        case "registerCoupon":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <Start navigate={updateModal}/>
            ) : (
                <DataTicket navigate={updateModal}/>
            );
            break;

        case "checkout":
            JSXElement = (
                <Checkout
                    // onSubmit={onSubmit}
                    values={body}
                    handleChange={handleChange}
                    navigate={updateModal}
                />
            );
            break;
    
        case "finalRegister":
            JSXElement = <Congratulations onClickBtn={updateModal}/>
            break;

        case "consultWinner":
            JSXElement = Object.keys(userContext).length === 0 ? (
                <LogIn navigate={updateModal} select='consultWinner'/>
            ) : (
                <UserTickets navigate={updateModal} />
            );
            break;
        
        case "passwordRecovery":
            JSXElement = <PasswordRecovery/>
            break;

        case "changePassword":
            JSXElement = <ChangePassword/>
            break;

        case "final":
            JSXElement = <Thanks 
                            values={body}
                            handleChange={handleChange}
                            navigate={updateModal}
                            close={props.onClose}
                            onClickBtn={updateModal}
                        />
            break;

        case "confirmationEmail":
            JSXElement = <ConfirmationAccount navigate={updateModal}/>
            break;

        case "3ds":
            JSXElement = <PaymentResult navigate={updateModal}/>
            break;

        case "prizesHistory":
            JSXElement = <TableWinners/>;
            break;

        case "paymentWait":
            JSXElement = <PaymentWait/>;
            break;

        case "PaymentExpired":
            JSXElement = <PaymentExpired/>;
            break;

        default:
            // JSXElement = <LogIn navigate={updateModal} select={select}/>
            break;
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block lg:p-0">

            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>


            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle lg:max-w-3xl w-full">
                <div className="flex px-4 py-4 items-center justify-between" style={{ backgroundImage: `url(${modalHeader})`, backgroundSize: 'cover' }}>
                    <div className="sm:flex bg-white py-3 px-3 rounded-20px inline-flex flex">
                        <img src={logoUnete} className="h-3 sm:h-8 mx-1 my-1 sm:my-0 " alt="Logo Pepsi" />
                        <img src={logoGTC} className="h-3 sm:h-10 mx-1 my-1 sm:my-0 " alt="Logo Pepsi" />
                        <img src={logoTigo} className="h-3 sm:h-10 mx-1 my-1 sm:my-0 " alt="Logo Banco GyT" />
                        <img src={logoPepsi} className="h-3 sm:h-10 mx-1 my-1 sm:my-0 " alt="Logo Campero" />
                        <img src={logoCampero} className="h-3 sm:h-10 mx-1 my-1 sm:my-0 " alt="Logo Tigo" />
                    </div>
                    <div>
                        <button 
                                type="button" 
                                className="inline-flex h-12 w-12 justify-center rounded-full  shadow-sm bg-unete-primary-yellow text-4xl font-semibold text-unete-blue focus:outline-none"
                                onClick={onCloseChange}
                            >
                            <img src={iconX} alt="" className=" object-cover p-2" />
                        </button>
                    </div>
                    
                </div>
                <div className=" w-full sm:flex text-lg"  style={{ backgroundImage: `url(${modalBack})`, backgroundSize: 'cover' }}>
                   {JSXElement}
                </div>
            </div>
        </div>
    </div>
    );
}

export default Modals;