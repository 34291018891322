import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios, { AxiosRequestConfig } from 'axios';

//Components
import Footer from '../Footer/Footer';
import HeroSection from '../Hero/Hero';
import Calendar from '../sections/Calendar';
import Rewards from '../sections/Rewards';
import Tickets from '../sections/Tickets';
import Winners from '../sections/Winners';
import Partners from '../sections/Partners';
import Info from '../sections/Info';
import Contact from '../sections/Contact';
import Modals from '../Modals/Modals';
import { useUser } from '../../context/cart-context';

import generalBack from '../../assets/img/Fondo gris.png';


Modal.setAppElement('#root');

const modalStyle = {
    content: {
        top: '0%',
        left: '0%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: "0",
        backgroundColor: "transparent",
        border: "none",
    },
};

const config3:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/pay/expired`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const Home = () => {
    
    const { userContext, setUserContext } = useUser();
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState<string>("");
    const [navigate, setNavigate] = useState<string>("");
    const [optionSelected, setOptionSelected] = useState<string>("");


    const openModal = () => {
        setModal(true);
    }

    const afterOpenModal = () => {

    }

    const closeModal = () => {
        setModal(false);
    }


    const modalTickets = (type:string) => {
        setSelected(type);
        setNavigate('selectTicket');
        setOptionSelected('selectTicket');
        setModal(true);
    }

    const modalGeneric = (navigate:string, selected:string) => {
        setModal(true);
        setNavigate(navigate);
        setOptionSelected(selected);
    }

    useEffect(() => {

        /*const getExpired = async () => {
            try {
                modalGeneric('PaymentExpired','');
                config3.url += `?userId=${userContext.id}`;
                axios(config3).then( (responseExpired) => {
                    if(responseExpired.status === 200){
                        if(responseExpired.data && responseExpired.data.length > 0){
                            if(responseExpired.data[0].status == 3){
                                console.log('////////////////',responseExpired)
                            }else{
                                closeModal();
                            }
                        }
                    }
                }).catch((error) => {
                });
            } catch (error) {
                console.log(error)
            }
        };*/

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let status = params.get("status")?.toString();
    
        if (status === "1") {
            modalGeneric('3ds','');
        } else if (status === "2") {
            let code = params.get("code")?.toString();
            if (code) {
                modalGeneric('anonymousRegister','-');
                // setModal(true);
                // setNavigate("anonymousRegister");
            } else {
                toast.error("Fallo al registrar ticket Tigo.");
            }
        } else if (status === "3") {
            let key = params.get("key")?.toString();
            if (key) {
                modalGeneric('changePassword','');
                // setModal(true);
                // setNavigate("changePassword");
            } else {
                toast.error("Fallo al cambiar contraseña.");
            }
        } else if (status === "4") {
            let key = params.get("key")?.toString();
            if (key) {
                let search = window.location.search;
                let params = new URLSearchParams(search);
            
                const userId = params.get("userId")?.toString();
                const recoveryKey = params.get("key")?.toString();
                window.history.pushState({}, document.title, window.location.pathname);
                const body = {
                    userId: userId,
                    key: recoveryKey
                };
            
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_USER_BASE_URL}/users/confirm`,
                        headers: {
                            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                            "Content-Type": "application/json",
                        },
                        data: body,
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            const wa = (params.get("wa")?.toString()) === 'true';
                            
                            if (wa) {
                                const phone = params.get("phone")?.toString();
                                toast.success("¡Validación desde Whatsapp Exitosa!");
                                const urlWaWebhook = process.env.REACT_APP_WEBHOOK_WA
                                axios({
                                    method: 'POST',
                                    url: urlWaWebhook,
                                    data: {
                                        userId: phone,
                                        data: response.data
                                    }
                                }).then((waReturn) => {
                                    const phoneWa = process.env.REACT_APP_WA_PHONE
                                    window.location.href = `https://api.whatsapp.com/send/?phone=${phoneWa}&text&type=phone_number&app_absent=0`;
                                })
                            }
                            
                            localStorage.setItem("user", JSON.stringify(response.data));
                            setUserContext(response.data);
                            toast.success("¡Bienvenido!");
                            modalGeneric('options','');
                            // setNavigate("menu")
                            //Remove Query Params
                        }
                    })
                    .catch((error) => {
                        toast.error("Datos inválidos de activación.");
                    });
                
            } else {
                toast.error("Fallo al confirmar cuenta.");
            }
        } else if (status === "5") {
            let key1 = params.get("key1")?.toString();
            let key2 = params.get("key2")?.toString();
            if (key1 && key2) {
                modalGeneric('final','');
            } else {
                toast.error("URL invalida.");
            }
        } else {
            closeModal();
        }
        //getExpired();
        // getComments();
      }, [setUserContext]);

    return (
        <div className="w-full"  style={{ backgroundImage: `url(${generalBack})`, backgroundSize: 'contain' }}>

        <HeroSection onClickBtn={modalGeneric}/>

        <Tickets 
            onClickTicket={modalTickets}
            navigate={modalGeneric}
        />
        <Rewards onClickBtn={modalGeneric}/>
        <Winners onClickBtn={modalGeneric}/>
        <Calendar onClickBtn={modalGeneric}/>
        <Info onClickBtn={modalGeneric}/>
        <Partners onClickBtn={modalGeneric}/>
        <Contact/>
        
        <Footer/>

        <Modal
            isOpen={modal}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyle}
            contentLabel="Example Modal"
        >
            <Modals 
                onClose={closeModal}
                selected={selected}
                section={navigate}
                setNavigate={setNavigate}
                optionSelected={optionSelected}
            />    
        </Modal>

        <ToastContainer 
            position="bottom-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
        />

        </div>
    );
}

export default Home;