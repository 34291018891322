import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';
import axios, { AxiosRequestConfig } from 'axios';

//Components
import Terms from '../Button/Terms';
import TagManager from 'react-gtm-module'

const config2:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/pay/notify`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const LogIn = (props: any): JSX.Element => {

    const { setUserContext } = useUser();

    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: '',
    })

    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLoginInfo({ ...loginInfo, [name]: value });
    };

    const Login = async (e:any) => {

        toast.info('Se está iniciando sesión');
        e.preventDefault();

        const body = {
            email: loginInfo.email,
            password: loginInfo.password,
        };

        try {
            const responseLogin = await axios({
                method: "post",
                url: `${process.env.REACT_APP_USER_BASE_URL}/users/login`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
                data: body,
            });

            if(responseLogin.status === 200){
                const responseUserInfo = await axios({
                    method: "get",
                    url: `${process.env.REACT_APP_USER_BASE_URL}/users/${responseLogin.data.id}`,
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                    },
                });

                config2.url += `?userId=${responseUserInfo.data.id}&notify=0&status=1`;
                await axios(config2).then( (responseNotify) => {
                    console.log(responseNotify)
                    if(responseNotify.status === 200){
                        for (const notify of responseNotify.data){
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'purchase',
                                    allow_custom_scripts: true,
                                    value: notify.amount,
                                    transaction_id: notify.orderId,
                                    sendTo: `DC-11146065/sales/${notify.referenceCode}`
                                }
                            });
                        }
                    }
                }).catch((error) => {
                });

                if (responseUserInfo.status === 200) {
                    toast.success("¡Bienvenido!");
                    localStorage.setItem("user", JSON.stringify(responseUserInfo.data));
                    setUserContext(responseUserInfo.data);
                    setNavigate(props.select);
                        
                } else if (responseUserInfo.status === 404) {
                    toast.error("No se pudo iniciar sesión, inténtalo más tarde.");
                } else {
                    toast.error("Algo salió mal, inténtalo de nuevo.");
                }
            } else if (responseLogin.status === 401) {
                toast.error("Usuario o contraseña incorrectos");
            } else if (responseLogin.status === 201) {
                setNavigate("confirmationEmail");
            } else {
                toast.error("Algo salió mal, inténtalo de nuevo.");
            }
        } catch (error) {
            toast.error("Usuario o contraseña incorrectos");
        }
    }

    const singUp = () => {
        props.navigate('sing-up')
    }


    useEffect( () => {
    }, []);


    return (
        <div className="w-full py-3">
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>

            <div className="mx-3 sm:mx-10">
                <p className="text-unete-blue text-xl font-bold">INICIA SESIÓN</p>
                <p className="text-unete-grey">
                    Ingresa tu correo electrónico y tu contraseña para que puedas realizar tus compras, ingresar números de cupón y verificar cupones ganadores
                </p>
            </div>
            
            <div className="sm:mx-20 py-4">
                <div className="flex flex-wrap mb-0">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Correo electrónico:</label>
                        <input
                            name="email"
                            value={loginInfo.email}
                            onChange={handleChange}
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Contraseña:</label>
                        <input
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-1 leading-tight text-xl"
                            type="password"
                            name="password"
                            value={loginInfo.password}
                            onChange={handleChange}
                        />
                        <div className="flex justify-end">
                            <span className="text-unete-blue underline cursor-pointer" onClick={() => {setNavigate('passwordRecovery')}}>¿Olvidaste tu contraseña?</span>
                        </div>
                    </div>
                </div>
                
                <div className="flex justify-between items-center py-6 px-3">
                    <div className="ml-4 text-unete-gray-n">
                        ¿Aún no tienes cuenta? <span onClick={singUp} className="text-unete-blue underline cursor-pointer">Regístrate</span>
                    </div>
                    <button 
                        className="text-unete-white-n text-xl bg-unete-green-n pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                        onClick={Login}
                    >SIGUIENTE</button>
                </div>
            </div>  
                  
        </div>
    );
}

export default LogIn;