import React from 'react';
import { Link } from 'react-router-dom';

//Assets
import twitter from '../../assets/img/icon-tt.png';
import facebook from '../../assets/img/icon-fb.png';
import instagram from '../../assets/img/icon-ig.png';
import whatsapp from '../../assets/img/icon-wa.png';

//Components


const Social = (props: any): JSX.Element => {

    return (
        <div className="flex justify-center">
            <a href="https://twitter.com/UneteGT" className="mx-2 cursor-pointer" target="_blank">
                <img src={twitter} alt="Twitter social network" className="h-10" />
            </a>
            <a href="https://www.facebook.com/UneteGuatemala/" className="mx-2 cursor-pointer" target="_blank">
                <img src={facebook} alt="Facebook social network" className="h-10" />
            </a>
            <a href="https://www.instagram.com/uneteguate/ " className="mx-2 cursor-pointer" target="_blank">
                <img src={instagram} alt="Instagram social network" className="h-10" />
            </a>
            <a href="https://wa.me/50224960407" className="mx-2 cursor-pointer" target="_blank">
                <img src={whatsapp} alt="Whatsapp social network" className="h-10" />
            </a>
        </div>
    );

}

export default Social;