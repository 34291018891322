import React, { useMemo, useState } from "react";

const UserContext = React.createContext({} as any);

export const UserProvider = (props: any) => {
  let user: any = localStorage.getItem("user");

  const [userContext, setUser] = useState(JSON.parse(user || '{}'));

  const setUserContext = (items: any) => {
    setUser(items);
  };

  const value = useMemo(() => {
    return {
      userContext,
      setUserContext,
    };
  }, [userContext]);

  return <UserContext.Provider value={value} {...props} />;
};

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser debe estar dentro del proveedor UserContext");
  }
  return context;
}
