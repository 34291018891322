exports.dates = [
    {
        name: 'Juego 1',
        lotery: '19 de Sept',
        date: '19/09/2022',
    },
    {
        name: 'Juego 10',
        lotery: '21 de Nov',
        date: '21/11/2022',
    },
    {
        name: 'Juego 2',
        lotery: '26 de Sept',
        date: '26/09/2022',
    },
    {
        name: 'Juego 11',
        lotery: '28 de Nov',
        date: '28/11/2022',
    },
    {
        name: 'Juego 3',
        lotery: '3 de Oct',
        date: '03/10/2022',
    },
    {
        name: 'Juego 12',
        lotery: '5 de Dic',
        date: '05/12/2022',
    },
    {
        name: 'Juego 4',
        lotery: '10 de Oct',
        date: '10/10/2022',
    },
    {
        name: 'Juego 13',
        lotery: '12 de Dic',
        date: '12/12/2022',
    },
    {
        name: 'Juego 5',
        lotery: '17 de Oct',
        date: '17/10/2022',
    },
    {
        name: 'Juego 14',
        lotery: '19 de Dic',
        date: '19/12/2022',
    },
    {
        name: 'Juego 6',
        lotery: '24 de Oct',
        date: '24/10/2022',
    },
    {
        name: 'Juego 15',
        lotery: '26 de Dic',
        date: '26/12/2022',
    },
    {
        name: 'Juego 7',
        lotery: '31 de Oct',
        date: '31/10/2022',
    },
    {
        name: 'Juego 16',
        lotery: '2 de Ene',
        date: '02/01/2023',
    },
    {
        name: 'Juego 8',
        lotery: '7 de Nov',
        date: '07/11/2022',
    },
    {
        name: 'Juego 17',
        lotery: '9 de Ene',
        date: '09/01/2023',
    },
    {
        name: 'Juego 9',
        lotery: '14 de Nov',
        date: '14/11/2022',
    },
    {
        name: 'Juego Fin',
        lotery: '9 de Feb',
        date: '09/02/2022',
    }
]