import React, { useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from "react-toastify";
import axios from "axios";
import InputMask from "react-input-mask";
import Terms from '../Button/Terms';

const DataTicket = (props: any): JSX.Element => {

    const [isLoading, setIsLoading] = useState(false);
    const { userContext } = useUser();
    const [ticketInfo, setTicketInfo] = useState({
        number: '',
        code: '',
    })

    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setTicketInfo({ ...ticketInfo, [name]: value });
    };
    
    const registerTicket = async (e:any) => {
        e.preventDefault();

        if(ticketInfo.number === '' && ticketInfo.code === ''){
            toast.error('El número de ticket y código de confirmación son requeridos.');
            return;
        }

        setIsLoading(true);
        const body = {
            userId: userContext.id,
            ticketId: Number(ticketInfo.number),
            code: ticketInfo.code,
        };
    
        try {
            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
                data: body,
            });
            if (response.status === 201) {
                    setNavigate("finalRegister");
                    toast.success("El ticket se registró con éxito.");
                    setTicketInfo({number: '', code: ''})
                    return;
            } else {
                    toast.error("Ticket ya registrado.");
            }
        } catch (error) {
            if (error.response.status === 409) {
                toast.error("Ticket ya usado o registrado.");
            } else {
                toast.error("Error al registrar el ticket.");
            }
        }
        setIsLoading(false);
    }

    const userName = userContext.name ? userContext.name : '';
    return (
        <div className="w-full">
            <div className="text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>
            <div className="sm:mx-20 text-unete-grey p-2 mb-6">
                <p className="mb-8 px-2 sm:px-0">Bienvenido (a) <strong className="text-unete-blue font-bold">{userName}</strong></p>
                <h4 className="text-unete-blue uppercase font-bold text-xl px-2 sm:px-0">Datos del ticket y cupón comprado</h4>
                <p className="leading-none mb-5 mt-2 px-2 sm:px-0">Ingresa tus datos para participar en los sorteos semanales, cada semana habrán 10 premios de Q3,000.00</p>
                <div className="flex flex-wrap mb-0">
                    <div className="w-full px-3">
                        <label className="block tracking-wide  mb-1 ">Número de ticket:</label>
                        <InputMask
                            mask="99999999"
                            maskChar={null}
                            type="text"
                            name="number"
                            value={ticketInfo.number}
                            onChange={handleChange}
                            required
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-0">
                    <div className="w-full px-3">
                        <label className="block tracking-wide  mb-1 ">Código de confirmación:</label>
                        <InputMask
                            mask="*************"
                            maskChar={null}
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="text"
                            name="code"
                            value={ticketInfo.code}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-center">
                    <button 
                        className="bg-unete-green-n text-unete-white-n pt-3 pb-2 px-12 my-4 rounded-20px font-bold focus:outline-none"
                        onClick={registerTicket}
                        disabled={isLoading}
                    >REGISTRAR</button>
                </div>
            </div>  
        </div>
    );
}

export default DataTicket;