import React from 'react';
import { Link } from 'react-scroll';

//Assets


const Partners = (props: any) => {
    return (
        <br />
    );
}

export default Partners;