import React, { useEffect } from 'react';
import { useUser } from '../../context/cart-context';

//Assets
import imgBg from '../../assets/img/bg-room.png';
import boy from '../../assets/img/bg-boy3.png';
import girl from '../../assets/img/bg-girl2.png';


//Components
import Buy from '../Button/Buy';
import Register from '../Button/Register';
import TicketWinner from '../Button/TicketWinner';
import Terms from '../Button/Terms';

const Options = (props: any): JSX.Element => {

    const { userContext } = useUser();

    const userName = userContext.name ? userContext.name : '';

    

    useEffect( () => {
        console.log('Options case...');
    });

    return (
        <div className=" bg-no-repeat p-3 bg-center relative w-full bg-cover">
            <div className="hidden sm:flex w-4/12 bg-no-repeat absolute top-0 left-0 h-full bg-contain bg-left-bottom z-0" style={{backgroundImage:`url(${boy})`, marginTop: '55px'}}></div>
            <div className="hidden sm:flex w-4/12 bg-no-repeat absolute top-0 right-0 h-full bg-contain bg-right-bottom z-0" style={{backgroundImage:`url(${girl})`, marginTop: '40px'}}></div>
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>

            <div className="flex justify-center items-center">
                <div className="w-full sm:w-72">
                
                    <p className="mb-2 text-center text-unete-gray-n">
                        Bienvenido (a) <strong className="text-unete-blue-n font-bold">{userName}</strong>
                    </p>
                    <p className="text-center text-unete-green-n font-semibold text-xl mb-8">¿Qué deseas realizar?</p>
        
                    <div className="mb-16 flex flex-col justify-center mx-8">
                       
                        <Buy size='sm' onClickBtn={props.navigate}/>
                        <TicketWinner size='sm' onClickBtn={props.navigate}/>
                        <Register size='sm' onClickBtn={props.navigate}/>
                    
                    </div>
                </div>
            </div>
        
        </div>
    );
}

export default Options;