import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../context/cart-context';
import axios from 'axios';
import ImputMask from 'react-input-mask';

const AnonymousSingUp = (props: any): JSX.Element => {

    const { setUserContext } = useUser();
    const [userStatus, setUserStatus] = useState(0);
    const [ticketId, setTicketId] = useState('');
    const [ticketCode, setTicketCode] = useState('');
    const [accountInfo, setAccountInfo] = useState({
        dpi: '',
        fullName: '',
        email: '',
        phone: '',
        department: 'ninguno',
    })

    const [validPassword, setValidPassword] = useState(0);
    const [validEmail, setvalidEmail] = useState(0);
    const [validDPI, setvalidDPI] = useState(0);
    const [validTel, setvalidTel] = useState(0);
    const [validDep, setvalidDep] = useState(0);
    const [validTigo, setvalidTigo] = useState(0);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setAccountInfo({ ...accountInfo, [name]: value });
    };

    const handleChangeName = (e: any) => {
        let { name, value } = e.target;
        value = value.replace(/[^a-z ]/gi, '');
        setAccountInfo({ ...accountInfo, [name]: value });
    };

    const handleChangeNumber = (e: any) => {
        let { name, value } = e.target;
        value = value.replace(/[^0-9]/gi, '');
        setAccountInfo({ ...accountInfo, [name]: value });
    };


    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const registeUser = async (e:any) => {



        if (validDPI === 0) {
            toast.error('El DPI es erroneo')
            return;
        }

        if(!accountInfo.fullName || accountInfo.fullName == '') {
            toast.error('El nombre es obligatorio')
            return;
        }

        if(validEmail === 0) {
            toast.error('El e-mail es erroneo')
            return;
        }

        if (validTel === 0) {
            toast.error('El teléfono es erroneo')
            return;
        }

        if (validDep === 0) {
            toast.error('El departamento es erroneo')
            return;
        }

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let status = params.get("status")?.toString();
        //Remove Query Params
        window.history.pushState({}, document.title, window.location.pathname);
        e.preventDefault();

        const body = {
            dpi: accountInfo.dpi,
            name: accountInfo.fullName,
            email: accountInfo.email,
            phone: accountInfo.phone,
            department: accountInfo.department,
            status: userStatus,
        };

        const register = () => {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_USER_BASE_URL}/users`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
                data: body,
            }).then((responseRegister) => {
                if (responseRegister.status === 201) {
                    localStorage.setItem("user", JSON.stringify(responseRegister.data));
                    setUserContext(responseRegister.data);
                    console.log(status);
                    if (status === "2") {
                        axios({
                            method: "post",
                            url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets`,
                            headers: {
                                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                                "Content-Type": "application/json",
                            },
                            data: {
                                userId: responseRegister.data.id,
                                ticketId: Number(ticketId),
                                code: ticketCode,
                            },
                        }).then(response => {
                            if (response.status === 201) {
                                setNavigate("finalRegister");
                                toast.success("El ticket se registró con éxito.");
                                setTicketId("");
                                setTicketCode("");
                                return;
                            } else {
                                toast.error("Ticket ya registrado.");
                            }
                        }).catch(error => {
                            if (error.response.status === 409) {
                                toast.error("Ticket ya usado o registrado.");
                            } else {
                                toast.error("Error al registrar el ticket.");
                            }
                        });
                    } else {
                        setNavigate(props.select);
                        toast.success("¡Bienvenido!");
                    }
                }
            }).catch((error) => {
                console.log(error)
                console.log('prueba'+error)
                if (error.response.status === 400) {
                    toast.error("Error a ingresar los datos, intentar más tarde.");
                }
                if (error.response.status === 409) {
                    toast.error("El e-mail ya fue registrado.");
                }
                if (error.response.status === 401) {
                    toast.error("La contraseña no cumple los requisitos de seguridad.");
                }
            })
        };

        if (props.select === "registerCoupon") {
            if (accountInfo.dpi && accountInfo.fullName && accountInfo.phone) {
                register();
            } else {
                toast.error("Debe completar todos los campos.");
                return;
            }
        } else {
            if (accountInfo.email && accountInfo.dpi && accountInfo.fullName && accountInfo.phone) {
                register();
            } else {
                toast.error("Debe completar todos los campos.");
                return;
            }
        }
    }

    const getTigoInfo = (token: string) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_TIGO_BASE_URL}/etickets/${token}`,
            headers: {
                "x-api-key": `${process.env.REACT_APP_TIGO_API_KEY}`,
                "Content-Type": "application/json",
            },
        }).then((response) => {
            setAccountInfo({...accountInfo , phone: response.data.userPhone });
            // setPhone(response.data.userPhone);
            setTicketId(response.data.ticketId);
            setTicketCode(response.data.ticketCode);
            toast.success("Por favor ingresa tus datos para continuar.");
        }).catch(() => {
            toast.error("Ticket Tigo no encontrado.");
        });
    };

    const logIn = () => {
        props.navigate('login',props.select)
    }

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get("code")?.toString();
        setUserStatus(0);
    
        if (code && validTigo == 0) {
            setUserStatus(3);
            getTigoInfo(code);
            setvalidTigo(1)
        }

        if (accountInfo.email) {
            if (accountInfo.email.length > 0) {
                const emailValido = new RegExp(`${process.env.REACT_APP_REGEX_EMAIL}`)
                if (emailValido.test(accountInfo.email)) {
                    setvalidEmail(1)
                } else {
                    setvalidEmail(0)
                }
            } else {
                setvalidEmail(0)
            }
        } else {
            setvalidEmail(0)
        }

        if (accountInfo.dpi.length == 13) {
            setvalidDPI(1)
        } else {
            setvalidDPI(0)
        }

        if (accountInfo.phone.length == 8 || accountInfo.phone.length == 11 || (accountInfo.phone.length == 12 && accountInfo.phone.includes('+'))) {
            setvalidTel(1)
        } else {
            setvalidTel(0)
        }
        console.log(accountInfo)
        if (accountInfo.department.toLowerCase() != 'ninguno') {
            setvalidDep(1)
        } else {
            setvalidDep(0)
        }

    }, [accountInfo.department, validDep, accountInfo.dpi, validDPI, accountInfo.phone, validTel, accountInfo.email, validEmail, validTigo, accountInfo, getTigoInfo]);

    return (
        <div className="w-full px-2 sm:px-6 py-3">
            <div className='text-unete-gray-n'>
                Ya tienes cuenta? <span onClick={logIn} className="text-unete-blue underline">Iniciar sesión</span>
            </div>
            <div className="flex justify-center sm:justify-start">
                <label className="text-unete-blue-n rounded-custom uppercase font-bold text-lg sm:text-2xl">COMPLETA LA INFORMACIÓN PARA SEGUIR</label>
            </div>
            <br/>
            <p className="text-unete-gray-n text-lg">Ingresa tus datos para que puedas realizar tus compras, ingresar números de cupón y verificar cupones ganadores</p>

            <div className="sm:mx-20 py-3 sm:p-3">
                <div className="flex flex-wrap mb-5">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">DPI:</label>
                        <input
                            name="dpi"
                            value={accountInfo.dpi}
                            onChange={handleChangeNumber}
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="text"
                        />
                    </div>
                    <label className="block text-red-700 text-xs ml-4 h-5">
                        {
                        (validDPI)===0 && <p>*El DPI ingresado es invalido*</p>
                        }
                    </label>
                </div>
                <div className="flex flex-wrap mb-5">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Nombre Completo:</label>
                        <input
                            name="fullName"
                            value={accountInfo.fullName}
                            onChange={handleChangeName}
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="text"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-5">
                    <div className="w-full px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Correo electrónico:</label>
                        <input
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="email"
                            name="email"
                            value={accountInfo.email}
                            onChange={handleChange}
                        />
                    </div>
                    <label className="block text-red-700 text-xs ml-4 h-5">
                        {
                        (validEmail)===0 && <p>*El correo ingresado es invalido*</p>
                        }
                    </label>
                </div>
                <div className="flex flex-wrap mb-5 items-end">
                    <div className="w-full sm:px-3">
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Número de teléfono:</label>
                        <input
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                            type="text"
                            name="phone"
                            value={accountInfo.phone}
                            onChange={handleChangeNumber}
                            required
                        />
                    </div>
                    <label className="block text-red-700 text-xs ml-4 h-5">
                        {
                        (validTel)===0 && <p>*El teléfono ingresado es invalido*</p>
                        }
                    </label>
                    <div className="w-full sm:px-3">        
                        <label className="block tracking-wide text-unete-gray-n mb-1 ">Departamento:</label>
                        <select 
                            onChange={handleChange}
                            name="department" 
                            id="department"
                            className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        >
                            <option value="Ninguno"> - Departamento - </option>
                            <option value="altaVerapaz">Alta Verapaz</option>
                            <option value="altaVerapaz">Alta Verapaz</option>
                            <option value="bajaVerapaz">Baja Verapaz</option>
                            <option value="chimaltenango">Chimaltenango</option>
                            <option value="chiquimula">Chiquimula</option>
                            <option value="peten">Petén</option>
                            <option value="elProgreso">El Progreso</option>
                            <option value="quiche">Quiché</option>
                            <option value="escuintla">Escuintla</option>
                            <option value="guatemala">Guatemala</option>
                            <option value="huehuetenango">Huehuetenango</option>
                            <option value="izabal">Izabal</option>
                            <option value="jalapa">Jalapa</option>
                            <option value="jutiapa">Jutiapa</option>
                            <option value="quetzaltenango">Quetzaltenango</option>
                            <option value="retalhuleu">Retalhuleu</option>
                            <option value="sacatepequez">Sacatepéquez</option>
                            <option value="sanMarcos">San Marcos</option>
                            <option value="santaRosa">Santa Rosa</option>
                            <option value="solola">Sololá</option>
                            <option value="suchitepequez">Suchitepéquez</option>
                            <option value="totonicapán">Totonicapán</option>
                            <option value="zacapa">Zacapa</option>
                        </select>
                    <label className="block text-red-700 text-xs ml-4 h-5">
                        {
                        (validDep)===0 && <p>*El departmento ingresado es invalido*</p>
                        }
                    </label>
                    </div>
                </div>
                <div className="flex justify-end px-3">
                    <button 
                        className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                        onClick={registeUser}
                    >SIGUIENTE</button>
                </div>
            </div>  
                  
        </div>
    );
}

export default AnonymousSingUp;