import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Terms from '../Button/Terms';

const PaymentExpired = (props: any): JSX.Element => {

    const [userEmail, setUserEmail] = useState<any>("");
    const [isLoading, setIsLoading] = useState<any>(false);
    const [resend, setResend] = useState<any>(false);

    const handleChange = (e: any) => {
        setUserEmail(e.target.value);
    };

    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const resendEmail = async () => {
        setIsLoading(true);
        window.open(props.values.link, '_blank', 'noopener,noreferrer');
        setIsLoading(false);
    };


    useEffect( () => {
        console.log('&&&&&&&&&&')
    },[]);

    return (
        <div className="w-full py-6 relative">
            <div className="text-sm mb-2 z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>
            <div className="w-full px-6 sm:px-10">
                <div className="my-4">
                    <p className="text-lg font-medium text-unete-grey">
                    Tienes un proceso de pago pendiente de finalizar.
                    </p>
                    <br />
                    <p className="text-lg font-medium text-unete-grey">
                    ¿Desas completar el proceso?
                    </p>
                </div>
            </div>      
        </div>
    );
}

export default PaymentExpired;