import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../context/cart-context';

//Assets
import imgBg from '../../assets/img/bg-room.png';
import boy from '../../assets/img/bg-boy.png';
import bgTitle from '../../assets/img/bg-ticket-btn.png';

//Components
import Buy from '../Button/Buy';
import Register from '../Button/Register';
import SocialButtons from '../Button/Social';
import Terms from '../Button/Terms';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import TagManager from 'react-gtm-module'


const Thanks = (props: any): JSX.Element => {

    const { userContext, setUserContext } = useUser();

    const [trigger, settrigger] = useState<boolean>(true);
    const [result, setResult] = useState<JSX.Element>();

    const history = useHistory()
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const userId = params.get("key1")?.toString();
    const orderId = params.get("key2")?.toString();
    const url = 'https://www.ayuvi.org.gt/';
    const navigateHome = () => {
        history.push('/');
        window.location.reload();
    };
    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }
    const generateLink = async (transaction:any) => {
        userContext.quantity = transaction.data.quantity;
        userContext.total = transaction.data.amount;
        setUserContext(userContext);
        setNavigate("paymentWait");
        /*toast.info('Se está iniciando proceso de compra');
        const getURLPay = await axios({
            method: "post",
            url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/pay`,
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
            },
            data: {
                userId: userContext.id,
                quantity: transaction.data.quantity,
                total: transaction.data.amount
            },
        });
        if(getURLPay.status === 200 && getURLPay.data.url){
            //window.open(getURLPay.data.url, '_self', 'noopener,noreferrer');
            userContext.url = getURLPay.data.url;
            setUserContext(userContext);
            setNavigate("paymentWait");
        }
        else toast.error("No se pudo realizar la conexion con el sitio de pago");*/
    };
    const htmlError = (<div className=" bg-no-repeat p-3 bg-center relative w-full bg-cover">
        <div className="mx-2 w-full">
            <br />
            <p className="text-lg font-medium text-unete-grey">
                El link es inválido por favor regresa al inicio: {" "}
                <span
                    className="text-unete-blue cursor-pointer font-bold"
                    onClick={() => navigateHome()}
                >
                    Inicio
                </span>
            </p>
            <br />
        </div>
    </div>);

    const copyText = async (e:any) => {
        e.preventDefault();
        if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(url);
        } else {
        return document.execCommand('copy', true, url);
        }
    }
    
    const verifyPayment = async () => {
        try {
            settrigger(false);
            const getValidate = await axios({
                method: "get",
                url: `${process.env.REACT_APP_TICKETS_BASE_URL}/pay/validate?userId=${userId}&orderId=${orderId}`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => response)
                .catch(({ response }) => response)
            if (getValidate.status === 200 && getValidate.data.status === 1) {
                if(getValidate.data.notify == 0){
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'purchase',
                            allow_custom_scripts: true,
                            value: getValidate.data.amount,
                            transaction_id: getValidate.data.orderId,
                            sendTo: `DC-11146065/sales/${getValidate.data.referenceCode}`
                        }
                    });
                }
                setResult(<div>

                    <div className="mx-2 w-full">
                        <p className="mb-2 ml-3 text-unete-gray-n">Bienvenido (a) <strong className="text-unete-blue-n font-bold">{userContext.name ? userContext.name : ''}</strong></p>
                        <p className="text-unete-grey text-center">Tu compra de Q{getValidate.data.amount} se ha realizado de manera exitosa</p>
                        <p className="uppercase text-center text-unete-blue font-bold text-4xl leading-none">¡Muchas gracias por tu apoyo!</p>
                    </div>
                    <div className="flex justify-start items-center">
                        <div className=" w-full">
                            {/* <p className="text-xs font-bold text-unete-grey mb-4">Tu compra de Q{getValidate.data.amount} se ha realizado de manera exitosa</p>
                            <div className="pt-3 pb-4 px-6 bg-contain bg-no-repeat flex justify-center w-80" style={{ backgroundImage: `url(${bgTitle})` }}>
                                <label className="uppercase text-center text-unete-blue font-bold text-3xl leading-none">¡Muchas gracias<br />por tu apoyo!</label>
                            </div> */}


                            <div className="flex flex-col sm:flex-row justify-center">
                                <div className='w-full sm:w-1/2'>                            
                                    <div className="bg-no-repeat md:absolute top-0 left-0 ml-16 md:ml-0 mt-4 md:mt-32 w-48 md:w-80 h-full bg-contain z-0">
                                        <img src={boy} alt="Boy" />
                                    </div>
                                </div>
                                <div className='w-full sm:w-1/2'>
<br/>
                                    <p className="text-unete-grey">
                                    Gracias a tu aporte 7 de cada 10 niños<br/>
                                    se pueden salvar.
                                    </p>
<br/>
                                    <p className="text-unete-grey text-sm">
                                    Compártelo con tus familiares y amigos para que se unan<br/>
                                    a la lucha contra el cáncer pediátrico.
                                    </p>

                                    <div className="my-8">
                                        <SocialButtons />
                                    </div>

                                    <div className="flex flex-col sm:flex-row justify-center font-semibold my-2 tracking-tight leading-none">
                                        <input
                                            className="appearance-none block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                            type="text"
                                            value={url}
                                            disabled
                                            style={{fontWeight: 200, borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px'}}
                                        />
                                        <button 
                                            className="text-unete-white-n text-xl bg-unete-blue-n font-bold focus:outline-none"
                                            style={{height: '51px', width:'120px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'}}
                                            onClick={copyText}
                                        >Copiar</button>
                                    </div>

                                    <div className="flex flex-col sm:flex-row justify-center font-semibold my-2 tracking-tight leading-none">
                                            <Buy size='sm' onClickBtn={props.onClickBtn}/>
                                            <Register size='sm' onClickBtn={props.onClickBtn}/>
                                    </div>
                                    <div className="text-unete-grey">
                                        <Terms />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>)
            } else if(getValidate.status === 200 && getValidate.data.status === 3){
                setResult((<div className=" bg-no-repeat p-3 bg-center relative w-full bg-cover">
                <div className="mx-2 w-full">
                    <br />
                    <p className="text-lg font-medium text-unete-grey text-center">
                        El link de tu compra de Q{getValidate.data.amount} ha expirado
                    </p>
                    <p className="text-lg font-medium text-unete-grey text-center">
                        ¿Deseas completar la compra?
                    </p>
                    <br />
                    <div className="flex justify-center items-center">
                        <button 
                            className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                            onClick={() => {generateLink(getValidate);}}
                        >Si</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button 
                            className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                            onClick={navigateHome}
                        >No</button>
                    </div>
                </div>
                </div>))
            }else {
                setResult(htmlError)
            }
        } catch (error) {
            setResult(htmlError)
        }
    };

    useEffect(() => {
        if (trigger) {
            verifyPayment();
        }
    });

    return (
        <div className=" bg-no-repeat p-3 bg-center relative w-full bg-cover">
            {result}
        </div>
    );
}

export default Thanks;