import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';
import axios from 'axios';

//Components
import Terms from '../Button/Terms';

const PasswordRecovery = (props: any): JSX.Element => {
    const [userEmail, setUserEmail] = useState<any>("");
    const [isLoading, setIsLoading] = useState<any>(false);

    const handleChange = (e: any) => {
        setUserEmail(e.target.value);
    };

    const sendEmail = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_USER_BASE_URL}/users/password/recovery`,
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
            },
            data: { email: userEmail }
        })
        .then((response) => {
            if (response.status === 201) {
                toast.success("Se ha enviado correo para recuperación de contraseña.");
                setUserEmail("");
            }
        })
        .catch((error) => {
            toast.error("No es posible recuperar la contraseña.");
        });
        setIsLoading(false);
    };

    return (
        <div className="w-full py-6 relative">
            <div className="text-sm mb-2 z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>
            <div className="w-full px-6 sm:px-10">

                <p className="text-lg font-medium text-unete-grey">Ingresa tu e-mail para recuperar la contraseña de tu cuenta.</p>
                
                <div className="w-full flex flex-col justify-center items-center">
                    <form className="w-full py-6 lg:px-16" onSubmit={sendEmail}>
                        <div className="flex flex-wrap -mx-3 mb-0">
                            <div className="w-full px-3">
                                <label className="block text-unete-grey font-medium mb-2 ml-4">E-mail:</label>
                                <input
                                type="email"
                                value={userEmail}
                                onChange={handleChange}
                                required
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-blue py-3 px-4 mb-3 leading-tight text-xl"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-wrap space-x-0">
                            <button
                                disabled={isLoading}
                                className="w-full h-12 mb-4 text-xl font-bold bg-unete-green-n text-white rounded-20px leading-none focus:outline-none"
                            >
                                RECUPERAR
                            </button>
                        </div>
                    </form>
                </div>

            </div>      
        </div>
    );
}

export default PasswordRecovery;