import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';
import axios from 'axios';

//Components
import Terms from '../Button/Terms';

const ChangePassword = (props: any): JSX.Element => {
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [validPassword, setValidPassword] = useState(0);

    const setNavigate = (navigate:string, select:string = 'options') => {
        props.navigate(navigate, select);
    }

    const handleChangePassword = (e: any) => {
        setNewPassword(e.target.value);
    };

    const handleChangeConfirmPassword = (e: any) => {
        setNewPasswordConfirmation(e.target.value);
    };

    const changePassword = async (e: any) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
    
        const userId = params.get("userId")?.toString();
        const recoveryKey = params.get("key")?.toString();
    
        
    
        e.preventDefault();
        const body = {
            userId: userId,
            key: recoveryKey,
            password: newPassword,
        };
    
        const change = () => {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_USER_BASE_URL}/users/password`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
                data: body,
            }).then((responseRegister) => {
                if (responseRegister.status === 200) {
                    const wa = (params.get("wa")?.toString()) === 'true';
                    //Remove Query Params
                    window.history.pushState({}, document.title, window.location.pathname);

                    if (wa) {
                        const phone = params.get("phone")?.toString();
                        const urlWaWebhook = process.env.REACT_APP_WEBHOOK_WA_PASSWORD
                        axios({
                            method: 'POST',
                            url: urlWaWebhook,
                            data: {
                                userId: phone,
                                data: {}
                            }
                        }).then((waReturn) => {
                            const phoneWa = process.env.REACT_APP_WA_PHONE
                            window.location.href = `https://api.whatsapp.com/send/?phone=${phoneWa}&text&type=phone_number&app_absent=0`;
                        })
                    }

                    setNewPassword('');
                    setNewPasswordConfirmation('');
                    toast.success("La contraseña se ha cambiado con éxito.");
                }
            }).catch((error) => {
                toast.error("Error al cambiar la contraseña.");
            });
        };
    
        if (newPassword === newPasswordConfirmation) {
            if(validPassword){
                change();

            }else{
                toast.error("La contraseña no cumple los requisitos de seguridad.");
            }
        } else {
            toast.error("La contraseña no coincide con la confirmación.");
            return;
        }
    };
  
    useEffect(()=>{
        if(newPassword){
            if(newPasswordConfirmation.length>0){
                if(newPassword === newPasswordConfirmation){
                    const strongPassword = new RegExp(`${process.env.REACT_APP_REGEX_PASSWORD}`)
                    if (strongPassword.test(newPassword)) {
                        setValidPassword(1)
                    } else {
                        setValidPassword(0)
                    }
                }else{
                    setValidPassword(2)
                }
            }else{
                setValidPassword(0)
            }
        }else{
            setValidPassword(0)
        } 
  
    },[newPassword, newPasswordConfirmation, validPassword]);

    return (
        <div className="w-full py-6 relative">
            <div className="text-sm mb-2 z-50 absolute bottom-0 w-full text-unete-gray-n">
                <Terms/>
            </div>
            <div className="w-full px-6 sm:px-10">

                <p className="text-lg font-medium text-unete-gray-n">Ingresa y confirma tu nueva contraseña de ingreso.</p>
                
                <div className="w-full flex flex-col justify-center items-center">
                    <form className="w-full py-6 lg:px-16" onSubmit={changePassword}>
                        <div className="flex flex-wrap -mx-3 mb-0">
                            <div className="w-full px-3">
                                <label className="block text-unete-gray-n font-medium mb-2 ml-4">Contraseña:</label>
                                <input
                                type="password"
                                value={newPassword}
                                onChange={handleChangePassword}
                                required
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-gray-n border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                />
                                <div className=" h-4 flex content-center text-red-700 text-xs font-medium">
                                    {(validPassword)===0 && <label>* La contraseña debe ser de al menos 8 caracteres e incluir al menos una letra mayúscula, una letra minúscula y un número.</label>}
                                </div>
                                <br />
                                <br className='md:hidden'></br>
                            </div>
                            <div className="w-full px-3">
                                <label className="block text-unete-gray-n font-medium mb-2 ml-4">Confirmar contraseña:</label>
                                <input
                                type="password"
                                value={newPasswordConfirmation}
                                onChange={handleChangeConfirmPassword}
                                required
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-gray-n border border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                />
                                <div className="h-5 flex content-center text-red-700 text-xs font-medium">
                                    {(validPassword)===2 && <label>* Las contraseñas no coinciden</label>}
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-wrap space-x-0">
                            <button className="w-full h-12 my-4 text-xl font-bold bg-unete-green-n text-white rounded-20px leading-none focus:outline-none">
                                Cambiar
                            </button>
                        </div>
                    </form>
                </div>

            </div>      
        </div>
    );
}

export default ChangePassword;