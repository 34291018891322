import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from "react-toastify";
import axios, { AxiosRequestConfig } from 'axios';
import data from './Dates';
import moment from 'moment';

//Assets
import ticketBg from '../../assets/img/bg-ticket-btn.png';
import title5 from '../../assets/img/title5.png';

//Components
import BuyBtn from '../Button/Buy';
import RegisterBtn from '../Button/Register';
import TagManager from 'react-gtm-module'

const config:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/prizes`,
    // url: 'https://hvcx2w5z33.execute-api.us-east-1.amazonaws.com/prod/tickets/prizes',
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const config2:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/pay/notify`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const Ticket = (ticket:any, key:number) => {
    // console.log('Ticket:',ticket.ticket.ticketId);
    return (
        <div key={key} className='bg-no-repeat h-16 lg:h-20 flex items-center justify-center bg-contain' style={{backgroundImage:`url(${ticketBg})`}}>
            <span className="text-xl sm:text-3xl xl:text-4xl font-semibold mb-6 sm:mb-2 text-unete-white-n">{ticket.ticket.ticketId}</span> 
        </div>
    );
}

const getActualDate = (date:string):boolean => {
    const dateCalendar = moment(date, 'DD/MM/YYYY');
    const dateNow = moment().startOf('day');
    
    if(dateCalendar.year() === dateNow.year()){
        const difference = dateCalendar.dayOfYear() - dateNow.dayOfYear();
        return (dateNow <= dateCalendar && difference >= 0 && difference < 7)
    } else {
        return false;
    }
}

const getDays = (date:string):number => {
    const dateCalendar = moment(date, 'DD/MM/YYYY');
    const dateNow = moment().startOf('day');
    return dateCalendar.dayOfYear() - dateNow.dayOfYear();
}


const Winners = (props: any): JSX.Element => {
    const { userContext } = useUser();
    const [prizes, setPrizes] = useState([]);
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState<string>("");
    const [navigate, setNavigate] = useState<string>("");
    const [optionSelected, setOptionSelected] = useState<string>("");

    const modalGeneric = (navigate:string, selected:string) => {
        setModal(true);
        setNavigate(navigate);
        setOptionSelected(selected);
    }

    let tableWinners = (
        <div className="w-full text-center text-unete-grey my-4">
            <hr className="w-40 mx-auto pb-4" />
            {/* <p className="text-center text-2xl">No hay ganadores :'(</p>  */}
        </div>
    );

    const ticketsWinners = prizes.map((prize:any, index:number) => {
        return <Ticket key={index} ticket={prize}/>
    });

    useEffect(() => {
        const getPrizes = async () => {
            try {
                axios(config).then( (responseRegister) => {
                    if(responseRegister.status === 200){
                        setPrizes(responseRegister.data.splice(0,10));
                    }
                }).catch((error) => {
                    if(error.response && error.response.status === 400){
                        console.error('No se pudo cargar el listado')
                    }
                });
            } catch (error) {
            }
        };

        const notifyPay = async () =>{
            try {
                config2.url += `?userId=${userContext.id}&notify=0&status=1`;
                axios(config2).then( (responseNotify) => {
                    console.log(responseNotify)
                    if(responseNotify.status === 200){
                        for (const notify of responseNotify.data){
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'purchase',
                                    allow_custom_scripts: true,
                                    value: notify.amount,
                                    transaction_id: notify.orderId,
                                    sendTo: `DC-11146065/sales/${notify.referenceCode}`
                                }
                            });
                        }
                    }
                }).catch((error) => {
                });
            } catch (error) {
                console.log(error)
            }
        }
        if(userContext.id){
            notifyPay();
        }
        getPrizes();
    }, []);
    
    if(prizes.length > 0) {
        tableWinners = (
            <div className="w-full p-1 mb-4 text-unete-grey grid gap-4 grid-cols-2 px-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                {ticketsWinners}
            </div>
        )
    }

    const date = data.dates.find((date) =>  getActualDate(date.date) !== false);
    const daysLeft = date !== undefined ? getDays(date.date) : 0;
    const dayText = date && daysLeft > 1 ? (
            <h3 className="text-unete-grey text-4xl uppercase text-center">¡FALTAN <strong className='text-unete-blue-n'>{daysLeft} DÍAS</strong> PARA EL SIGUIENTE!</h3>
        ) : (
            <h3 className="text-unete-grey text-4xl uppercase text-center">¡FALTAN <strong className='text-unete-blue-n'>{daysLeft} DÍA</strong> PARA EL SIGUIENTE!</h3>
        );
    const days = daysLeft !== 0 ? dayText : '';

    return (
        <div className="max-w-screen-xl mx-auto">

            <div className="flex justify-center">
            <div className="flex flex-col sm:flex-row">
                <div className="flex-auto">
                    <img src={title5} style={{width: '350px'}} alt="title5" />
                </div>
            </div>
        </div>
            {tableWinners}
            {days}
      
        </div>
    );
}

export default Winners;