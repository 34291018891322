import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Terms from '../Button/Terms';

const ConfirmationAccount = (props: any): JSX.Element => {

    const [userEmail, setUserEmail] = useState<any>("");
    const [isLoading, setIsLoading] = useState<any>(false);
    const [resend, setResend] = useState<any>(false);

    const handleChange = (e: any) => {
        setUserEmail(e.target.value);
    };

    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const resendEmail = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_USER_BASE_URL}/users/resend`,
            headers: {
              "x-api-key": `${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json",
            },
            data: { email: userEmail }
          })
          .then((response) => {
            if (response.status === 201) {
              toast.success("Se ha reenviado el correo a tu cuenta.");
              setUserEmail("");
            }
          })
          .catch((error) => {
            toast.error("Ha ocurrido un error. Intenta nuevamente");
          });
        setIsLoading(false);
    };


    useEffect( () => {
        
    },[]);

    const JSXElement = (
        <div className="w-full flex flex-col justify-center items-center">
            <form className="w-full py-6 lg:px-16" onSubmit={resendEmail}>
                <div className="flex flex-wrap -mx-3 mb-0">
                    <div className="w-full px-3">
                        <label className="block text-unete-gray-n font-medium mb-2 ml-4">Ingresa nuevamente tu correo electrónico:</label>
                        <input
                        type="email"
                        value={userEmail}
                        onChange={handleChange}
                        required
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-gray-n border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        />
                    </div>
                </div>
                <div className="w-full flex flex-wrap space-x-0">
                    <button
                        disabled={isLoading}
                        className="w-full h-12 mb-4 text-xl font-bold bg-unete-green-n text-white rounded-20px leading-none focus:outline-none"
                    >
                        Reenviar
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <div className="w-full py-6 relative">
            <div className="text-sm mb-2 z-50 absolute bottom-0 w-full text-unete-gray-n">
                <Terms/>
            </div>
            <div className="w-full px-6 sm:px-10">
                <div className="my-4">
                    <p className="text-lg font-medium text-unete-gray-n">
                    En la bandeja de entrada de tu correo electrónico se encuentra un e-mail para verificar la creación de tu cuenta. Por favor da click en el enlace enviado.
                    </p>
                    <br />
                    <p className="text-lg font-medium text-unete-gray-n">
                        ¿No has recibido ningún e-mail?{" "}
                        <span
                            className="text-unete-blue cursor-pointer font-bold"
                            onClick={() => setResend(true)}
                        >
                            Reenviar
                        </span>
                    </p>
                </div>
                {(resend) ? JSXElement :null}
            </div>      
        </div>
    );
}

export default ConfirmationAccount;