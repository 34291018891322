import React, { useEffect, useState } from 'react';

//Assets
import historial from '../../assets/img/Historial.png';

//Components


const Generic = (props: any): JSX.Element => {

    const [navigate, setNavigate] = useState('');

    const onClickButton = () => {
        props.onClickBtn(navigate,navigate)
    }

    const size = props.size ? props.size : 'base';
    const name = props.name ? props.name : '';
    const state = props.state ? props.state : '';

    useEffect( () => {
        setNavigate(state)
    }, []);

    switch (size) {
        case 'full':
            return (
                <button
                    className="mx-3 h-16 inline-block mb-4 p-0 focus:outline-none"
                    onClick={onClickButton}
                    >
                    <img
                        src={historial}
                        alt="Texto alternativo de la imagen"
                        className="h-16"
                    />
                </button>
            );
        case 'sm':
            return (
                <button
                    className="mx-3 h-10 inline-block mb-4 p-0 focus:outline-none"
                    onClick={onClickButton}
                    >
                    <img
                        src={historial}
                        alt="Texto alternativo de la imagen"
                        className="h-10"
                    />
                </button>
            );
        
        default:
            return (
                <button
                    className="mx-3 h-16 inline-block mb-4 p-0 focus:outline-none"
                    onClick={onClickButton}
                    >
                    <img
                        src={historial}
                        alt="Texto alternativo de la imagen"
                        className="h-16"
                    />
                </button>


            );
    }

}

export default Generic;