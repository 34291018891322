import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';
import axios from 'axios';

//Components
import Terms from '../Button/Terms';

const PaymentResult = (props: any): JSX.Element => {

    const { userContext } = useUser();

    const [trigger, settrigger] = useState<boolean>(true);
    const [result, setResult] = useState<string>("");

    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }

    const verifyPayment = async () => {
        try {
            settrigger(false);
            const request = await axios.post(
                `${process.env.REACT_APP_MIGO_BASE_URL}/getByPhone`,
                // "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/getByPhone",
                { phone: userContext.id }
            );
    
            const { token } = request.data[0];
            if (token) {
                const verifyTransaction = await axios.post(
                    `${process.env.REACT_APP_MIGO_BASE_URL}/transactionResults`,
                    // "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/transactionResults",
                    { token }
                );
        
                const { reasonCode } = verifyTransaction.data[0];
                if (reasonCode === "1") {
                
                    let search = window.location.search;
                    let params = new URLSearchParams(search);
                    let NTickets = Number(params.get("amount"));
                    //Remove Query Params
                    window.history.pushState({}, document.title, window.location.pathname);
                    const RegisterTickets = await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/assignments`,
                        headers: {
                            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                            "Content-Type": "application/json",
                        },
                        data: {
                            userId: userContext.id,
                            orderId: token,
                            quantity: Number(NTickets / 20), 
                        },
                    })
                    .then((response)=>response)
                    .catch(({response})=>response)
            
                    if(RegisterTickets.status===200){
                        setNavigate("final");
                    }else if(RegisterTickets.status===409){
                        setResult("Esta transacción ya había sido registrada anteriormente.")
                    }else{
                        setResult("Ha ocurrido un error. Intenta de nuevo")
                    }
                } else {
                    setResult("Lo sentimos tu pago fue rechazado, intenta de nuevo");
                }
            }
        } catch (error) {
            setResult("Ha ocurrido un error. Intenta de nuevo")
        }
        //setNavigate("final");
        // console.log(RegiterTickets)
        // //Aca debo mandar el correo
        // console.log(RegiterTickets.data);
    };

    useEffect(() => {
        if (trigger) {
            verifyPayment();
        }
    });

    const paymentResponse = result.length > 0 ? (
        <p className=" text-red-700 text-xl font-semibold text-center px-6">{result}</p>
    ) : (
        <p className=" text-unete-grey text-xl font-semibold text-center px-6">☑️ Verificando la transacción <br />Espera un momento</p>
    );

    return (
    <div className="w-full py-3">
        <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
        </div>
        <div className="w-full px-6 sm:px-10 pt-3 pb-6">
            <div className="flex justify-center overflow-hidden">
                {paymentResponse}
            </div>
            </div>
    </div>
    );
}

export default PaymentResult;