import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { useUser } from '../../context/cart-context';
import ImputMask from 'react-input-mask';
import "../../css/tablePrizes.css";

//Assets

//Components
import Terms from '../Button/Terms';
const config:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/prizes?start=2021-01-01`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const TableWinners = (props: any): JSX.Element => {
    const { userContext } = useUser();
    const [prizes, setPrizes] = useState([])
    const [query, setQuery] = useState("");

    const userName = userContext.name ? userContext.name : '';

    useEffect( () => {
        const getPrizes = async () =>{
            try {
                axios(config).then( (responseRegister) => {
                    if(responseRegister.status === 200){
                        setPrizes(responseRegister.data);
                        
                    }
                }).catch((error) => {
                    if(error.response && error.response.status === 400){
                        console.error('No se pudo cargar el listado')
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }
        getPrizes();
    }, []);


    const tablePrizes = '';
    // let prizesParsing = <p>No hay datos...</p>;


        const prizesParsing = prizes.filter((x:any) => x.ticketId.toString().includes(query)).map((prize:any) => {
            return (
            <tr key={prize.ticketId}>
                <td className="px-3">{prize.ticketId}</td>
                <td className="px-3">{moment(prize.awardedDate).format('DD/MM/YYYY')}</td>
            </tr>
            )
        })

    
    

    return (
        <div className="p-3 relative w-full">
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <b><Terms/></b>
            </div>
            <div className="flex justify-center">
                <div className="md:max-w-md mb-4 mx-4">
                    <h4 className="text-unete-gray-n uppercase font-bold text-xl px-2 sm:px-0 text-center">HISTORIAL DE GANADORES</h4>
                    <br/>
                    <div className="flex flex-wrap text-center">
                        <div className="w-full sm:px-3">
                            <label className="block tracking-wide text-unete-gray-n mb-1 text-left">Buscar:</label>
                            <ImputMask
                                name="search"
                                mask='9999999999999'
                                maskChar={null}
                                className="rounded-20px focus:outline-none bg-white text-unete-grey border border border-unete-green-n py-3 px-4 mb-3 leading-tight"
                                type="text"
                                placeholder='No. Ticket'
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    <table className="text-center m-auto">
                        <thead>
                            <tr className="font-bold text-unete-blue">
                                <th className="text-center text-unete-gray-n">Ticket</th>
                                <th className="text-center text-unete-gray-n">Fecha</th>
                            </tr>
                        </thead>
                        <tbody className="text-unete-blue-n">
                            {prizesParsing}
                        </tbody>
                    </table> 
                </div>
            </div>
            <br/>
        </div>
    );
}

export default TableWinners;