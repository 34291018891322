import React from 'react';
import Buy from '../Button/Buy';
import Register from '../Button/Register';
import Nav from '../Navigation/NavBar/Nav';
import '../../css/Hero.css'; // Importa tu archivo de estilos CSS aquí

import Titulo from '../../assets/img/TituloLecciones.png';
import Dibujos from '../../assets/img/Dibujos.png';
import uneteBgM from '../../assets/img/w_header_m.png';
import Fondo from '../../assets/img/Fondo_Verde.png';
import Aporte from '../../assets/img/TextoAporte.png';
import Participa from '../../assets/img/Participa.png';
import Compartir from '../../assets/img/compartir/COMPARTIR EN_.png';
import Facebook from '../../assets/img/compartir/FB.png';
import ig from '../../assets/img/compartir/IG.png';
import whats from '../../assets/img/compartir/WA.png';
import title1 from '../../assets/img/title1.png';

const Hero = (props: any): JSX.Element => {
  return (
    <header className="mb-10 w-full hero-section rounded-custom rounded-t-none relative bg-center">
      <Nav onClickBtn={props.onClickBtn} style/>

      {/* Sección visible en pantallas grandes */}
      <div className="hidden sm:block">
        <div
          className="flex justify-center items-center w-full"
          style={{
            backgroundImage: `url(${Fondo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            className="flex flex-col justify-center items-center w-full h-full"
            style={{
              backgroundImage: `url(${Dibujos})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >


<div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Titulo} alt="Titulo" style={{ width: '100%' }} />
  </div>
</div>
<div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Aporte} alt="Aporte" style={{ width: '100%' }} />
  </div>
</div>
<div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Participa} alt="Participa" style={{ width: '100%' }} />
  </div>
</div>

<div className="flex w-full mb-4 justify-center items-center">
  <Buy onClickBtn={props.onClickBtn} className="mr-4" />
  <Register onClickBtn={props.onClickBtn} />
</div>
<div className="flex w-full mb-4 justify-center items-center">
  <img
    src={Compartir}
    alt="Compartir"
    style={{ verticalAlign: 'middle', paddingRight: '20px', paddingBottom: "10px" }}
  />
  <a href="https://www.facebook.com/UneteGuatemala" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={Facebook} alt="Facebook" style={{ width: '100%' }} />
  </a>
  <a href="https://instagram.com/uneteguate?igshid=MzRlODBiNWFlZA==" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={ig} alt="Instagram" style={{ width: '100%' }} />
  </a>
  <a href="https://twitter.com/UneteGT?t=fNVjmA84f-b16EI5Z0cu5A&s=09" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={whats} alt="Twitter" style={{ width: '100%' }} />
  </a>
</div>


        </div>
      </div>
      </div>

      {/* Sección oculta en dispositivos móviles */}
      <div className="sm:hidden w-full h-50 items-center justify-center" style={{ backgroundImage: `url(${Fondo})`, backgroundSize: 'cover' }}>
        <br />
        <div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Titulo} alt="Titulo" style={{ width: '100%' }} />
  </div>
</div>
<div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Aporte} alt="Titulo" style={{ width: '100%' }} />
  </div>
</div>
<div className="flex w-full mb-4 justify-center items-center">
  <div className="w-50vw mb-4">
    <img src={Participa} alt="Titulo" style={{ width: '100%' }} />
  </div>
</div>
        <img src={title1} style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }} alt="title1" /> {/* Agregamos maxWidth y width: 100% para hacerlo responsive */}
        <div className="flex">
          <Buy onClickBtn={props.onClickBtn} className="mr-4" />
          <Register onClickBtn={props.onClickBtn} />
        </div>
        <div className="flex w-full mb-4 justify-center items-center">
          <br />
  <img
    src={Compartir}
    alt="Compartir"
    style={{ verticalAlign: 'middle', paddingRight: '20px', paddingBottom: "10px" }}
  />
  <a href="https://www.facebook.com/UneteGuatemala" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={Facebook} alt="Facebook" style={{ width: '100%' }} />
  </a>
  <a href="https://instagram.com/uneteguate?igshid=MzRlODBiNWFlZA==" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={ig} alt="Instagram" style={{ width: '100%' }} />
  </a>
  <a href="https://twitter.com/UneteGT?t=fNVjmA84f-b16EI5Z0cu5A&s=09" target="_blank" style={{ verticalAlign: 'middle', paddingRight: '10px' }}>
    <img src={whats} alt="Twitter" style={{ width: '100%' }} />
  </a>
</div>
        <br />
      </div>
    </header>
  );
}

export default Hero;
