import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';

//Assets
import bg from '../../assets/img/bg-room.png';
import bgTitle from '../../assets/img/btn-subtitle.png';
import bgGirl from '../../assets/img/bg-girl1.png';

//Components
import Terms from '../Button/Terms';

const config:AxiosRequestConfig = {
    method: "get",
    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/prizes?start=2021-01-01`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
}

const SelectTicket = (props: any): JSX.Element => {
    // Props: {navigate, values, quantity}

    const quantity = props.quantity ? props.quantity : 1;
    const { userContext, setUserContext } = useUser();
    const [customValue, setCustomValue] = useState(quantity);
    const [total, setTotal] = useState(0);

    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }

    const increaseTickets = () => {
        if(customValue < 20){
            setCustomValue(customValue + 1);
        }
    }

    const substractTickets = () => {
        if(customValue > 1){
            setCustomValue(customValue - 1);
        }
    }

    const sendValue = async (nextStep: string) => {
        try {
            if (Number(customValue) >= 1 && Number(customValue) <= 20) {
                props.values.customValue = total;
                userContext.quantity = customValue;
                userContext.total = total;
                setUserContext(userContext);
                setNavigate("paymentWait");
                /*toast.info('Se está iniciando proceso de compra');
                const getURLPay = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/pay`,
                    headers: {
                        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                        "Content-Type": "application/json",
                    },
                    data: {
                        userId: userContext.id,
                        quantity: customValue,
                        total: total
                    },
                });
                if(getURLPay.status === 200 && getURLPay.data.url){
                    //window.open(getURLPay.data.url, '_self', 'noopener,noreferrer');
                    userContext.url = getURLPay.data.url;
                    userContext.quantity = customValue;
                    userContext.total = total;
                    setUserContext(userContext);
                    setNavigate("paymentWait");
                }
                else toast.error("No se pudo realizar la conexion con el sitio de pago");*/
                
            } else toast.error("Valor no valido");
        } catch (error) {
            toast.error("No se pudo realizar la conexion con el sitio de pago");
        }
    };

    useEffect( () => {
        setTotal(customValue * 20);
        // setCustomValue(quantity);
    }, [total, customValue ,quantity]);


    const userName = userContext.name ? userContext.name : '';
    // return <p></p>;
    return (
        <div className="w-full py-6 relative bg-no-repeat bg-center bg-cover" >
            <div className="hidden sm:flex w-4/12 bg-no-repeat absolute top-0 right-0 h-full bg-contain bg-right-bottom" style={{backgroundImage:`url(${bgGirl})`}}></div>
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>

            <div className="px-3 sm:px-20 py-3 text-unete-grey">
                <p className="mb-2 px-2 sm:px-0 tracking-tight leading-none ">Bienvenido (a) <strong className="text-unete-blue font-bold">{userName}</strong></p>
                <span className="my-2 py-2 uppercase font-semibold text-xl text-unete-blue tracking-tight leading-none ">Compra tus tickets</span>
                <p className="leading-none mb-5 mt-3 text-base sm:text-lg px-2 sm:px-0 tracking-tight leading-none ">Cada ticket tiene un valor de Q20.00</p>
            
                <div className="w-full sm:flex sm:flex-col sm:w-8/12 justify-start">
                    <div className="flex">
                        <div className=" w-16 mx-1 inline-block mr-3">
                            <div onClick={substractTickets} className="h-10 w-10 bg-unete-green-n rounded-full flex justify-center items-center my-2 mx-auto text-unete-blue p-3 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="white" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                            </div>
                            <div className="text-center text-unete-gray-n font-semibold tracking-tight mb-2">
                                <p>Quitar Ticket</p>
                            </div>
                        </div>

                        <div className="w-48 sm:w-56 justify-center">
                            <div className="w-48 sm:w-56 mx-1 inline-block">
                                <div className="h-12 relative text-unete-gray-n mx-auto mb-3">
                                    <div className="h-10 w-10 -ml-3 sm:ml-2 bg-unete-green-n rounded-full absolute flex justify-center items-center z-10">
                                        <span className="text-4xl font-bold pt-1 text-unete-white-n">Q</span>
                                    </div>
                                    <div className="absolut flex justify-center h-12 w-full top-0 left-0 z-20">
                                        <label className="pt-2 pl-6 leading-none text-6xl font-bold">
                                            {total}<span className="text-4xl">.00</span>
                                        </label>
                                    </div>
                                </div>
                                <br/>
                                <div className="text-center text-unete-gray-n font-semibold tracking-tightmy-1">
                                    <p>Equivalente a<br/>{customValue} ticket</p>
                                </div>
                                <div className="flex justify-center">
                                    <div className="w-1/6 bg-unete-green h-1 rounded-full mx-1">&nbsp;</div>
                                </div>
                            </div>
                        </div>

                        <div className=" w-16 mx-1 inline-block">
                            <div onClick={increaseTickets} className="h-10 w-10 bg-unete-green-n rounded-full flex justify-center items-center my-2 mx-auto text-unete-blue p-3 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="white" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                            </div>
                            <div className="text-center text-unete-gray-n font-semibold tracking-tightmb-2">
                                <p>Agregar Ticket</p>
                            </div>
                        </div>
                    </div>

                    <button 
                        className="w-full h-12 mb-4 bg-unete-green-n text-white rounded-20px px-5 leading-none focus:outline-none"
                        onClick={() => {sendValue("checkout");}}
                    >
                        <div className="flex justify-center items-center">
                            <span className="mt-2 font-semibold text-xl">Comprar</span>
                        </div>
                    </button>
                
                </div>

            </div>  
        </div>
    );
}

export default SelectTicket;