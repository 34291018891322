import React from 'react';
import { Link } from 'react-router-dom';

//Assets
import twitter from '../../assets/img/icon-tt.png';
import facebook from '../../assets/img/icon-fb.png';
import instagram from '../../assets/img/icon-ig.png';
import whatsapp from '../../assets/img/icon-wa.png';

//Components


const Social = (props: any): JSX.Element => {

    return (
        <div className="flex justify-center">
            {/* <a href="" className="mx-2" target="_blank">
                Politicas de privacidad
            </a> */}
            <a href="/assets/terminos_condiciones.pdf" className="mx-2 font-bold" target="_blank">
                Términos y condiciones
            </a>
        </div>
    );

}

export default Social;