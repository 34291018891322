import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../context/cart-context';
import axios from 'axios';
import ImputMask from 'react-input-mask';

const SingUp = (props: any): JSX.Element => {

    const [currentPage, setCurrentPage] = useState(0);
    const [accountInfo, setAccountInfo] = useState({
        dpi: '',
        fullName: '',
        email: '',
        phone: '',
        department: 'ninguno',
        password: '',
        confirmPassword: '',
    })

    const [validPassword, setValidPassword] = useState(0);
    const [validEmail, setvalidEmail] = useState(0);
    const [validDPI, setvalidDPI] = useState(0);
    const [validTel, setvalidTel] = useState(0);
    const [validDep, setvalidDep] = useState(0);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setAccountInfo({ ...accountInfo, [name]: value });
    };

    const handleChangeName = (e: any) => {
        let { name, value } = e.target;
        value = value.replace(/[^a-z ]/gi, '');
        setAccountInfo({ ...accountInfo, [name]: value });
    };

    const handleChangeNumber = (e: any) => {
        let { name, value } = e.target;
        value = value.replace(/[^0-9]/gi, '');
        setAccountInfo({ ...accountInfo, [name]: value });
    };

    const setNavigate = (navigate:string) => {
        props.navigate(navigate);
    }

    const nextPage = () => {
        if(validPassword === 0 || validPassword === 2) {
            toast.error('Las contraseñas no coinciden')
            return;
        }
        if(validEmail === 0) {
            toast.error('El e-mail es erroneo')
            return;
        }

        setCurrentPage(1);
    }

    const prevPage = () => {
        setCurrentPage(0);
    }

    const registeUser = async (e:any) => {
        e.preventDefault();

        const body = {
            dpi: accountInfo.dpi,
            name: accountInfo.fullName,
            email: accountInfo.email,
            phone: accountInfo.phone,
            department: accountInfo.department,
            password: accountInfo.password,
            status: 1,
        };

        if(accountInfo.password !== accountInfo.confirmPassword) {
            toast.error('Las contraseñas no coinciden')
            return;
        }
        if(!accountInfo.fullName || accountInfo.fullName == '') {
            toast.error('El nombre es obligatorio')
            return;
        }

        if(validEmail === 0) {
            toast.error('El e-mail es erroneo')
            return;
        }

        if (validDPI === 0) {
            toast.error('El DPI es erroneo')
            return;
        }

        if (validTel === 0) {
            toast.error('El teléfono es erroneo')
            return;
        }

        if (validDep === 0) {
            toast.error('El departamento es erroneo')
            return;
        }

        const register = () => {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_USER_BASE_URL}/users`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
                data: body,
            }).then((responseRegister) => {
                    if (responseRegister.status === 201) {
                        setNavigate("confirmationEmail");
                    }
            }).catch((error) => {
                console.log(error.response.status);
                if (error.response.status === 400) {
                    toast.error("Error a ingresar los datos, intentar más tarde.");
                }
                if (error.response.status === 409) {
                    toast.error("El e-mail ya se encuentra registrado.");
                }
                if (error.response.status === 401) {
                    toast.error("La contraseña no cumple los requisitos de seguridad.");
                }
            });
        };

        if (props.select === "registerCoupon") {
            if (accountInfo.dpi && accountInfo.fullName && accountInfo.phone && accountInfo.password) {
              register();
            } else {
              toast.error("Debe completar todos los campos.");
              return;
            }
          } else {
            if (accountInfo.email && accountInfo.dpi && accountInfo.fullName && accountInfo.phone && accountInfo.password) {
              register();
            } else {
              toast.error("Debe completar todos los campos.");
              return;
            }
        }
    }

    const logIn = () => {
        props.navigate('login',props.select)
    }

    const page1 = (
        <div className="sm:mx-20 py-3 sm:p-3">
            <div className="flex flex-wrap mb-5">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Correo electrónico:</label>
                    <input
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="email"
                        name="email"
                        value={accountInfo.email}
                        onChange={handleChange}
                    />
                </div>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validEmail)===0 && <p>*El correo ingresado es invalido*</p>
                    }
                </label>
            </div>
            <div className="flex flex-wrap mb-0">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Contraseña:</label>
                    <input
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="password"
                        name="password"
                        value={accountInfo.password}
                        onChange={handleChange}
                    />
                </div>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validPassword)===0 && <p>*La contraseña debe poseer una longitud mínima de 8 caracteres*</p>
                    }
                </label>
                <br className='md:hidden'></br>
            </div>
            <div className="flex flex-wrap mb-0">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Confirmar contraseña:</label>
                    <input
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="password"
                        name="confirmPassword"
                        value={accountInfo.confirmPassword}
                        onChange={handleChange}
                    />
                </div>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validPassword)===2 && <p>*Las contraseñas no coinciden*</p>
                    }
                </label>
            </div>
            <div className="flex justify-end">
                <button 
                    className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                    onClick={nextPage}
                >Siguiente</button>
            </div>
        </div>  
    );

    const page2 = (
        <div className="sm:mx-20 py-3 sm:p-3">
            <div className="flex flex-wrap mb-5">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Nombre Completo:</label>
                    <input
                        name="fullName"
                        value={accountInfo.fullName}
                        onChange={handleChangeName}
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="text"
                    />
                </div>
            </div>
            <div className="flex flex-wrap mb-5">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">DPI:</label>
                    <input
                        name="dpi"
                        value={accountInfo.dpi}
                        onChange={handleChangeNumber}
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="text"
                    />
                </div>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validDPI)===0 && <p>*El DPI ingresado es invalido*</p>
                    }
                </label>
            </div>
            <div className="flex flex-wrap mb-5">
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Número de teléfono:</label>
                    <input
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                        type="text"
                        name="phone"
                        value={accountInfo.phone}
                        onChange={handleChangeNumber}
                    />
                </div>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validTel)===0 && <p>*El teléfono ingresado es invalido*</p>
                    }
                </label>
                <div className="w-full sm:px-3">
                    <label className="block tracking-wide text-unete-gray-n mb-1 ">Departamento:</label>
                    <select 
                        onChange={handleChange}
                        name="department" 
                        id="department"
                        className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                    >
                        <option value="Ninguno"> - Departamento - </option>
                        <option value="altaVerapaz">Alta Verapaz</option>
                        <option value="altaVerapaz">Alta Verapaz</option>
                        <option value="bajaVerapaz">Baja Verapaz</option>
                        <option value="chimaltenango">Chimaltenango</option>
                        <option value="chiquimula">Chiquimula</option>
                        <option value="peten">Petén</option>
                        <option value="elProgreso">El Progreso</option>
                        <option value="quiche">Quiché</option>
                        <option value="escuintla">Escuintla</option>
                        <option value="guatemala">Guatemala</option>
                        <option value="huehuetenango">Huehuetenango</option>
                        <option value="izabal">Izabal</option>
                        <option value="jalapa">Jalapa</option>
                        <option value="jutiapa">Jutiapa</option>
                        <option value="quetzaltenango">Quetzaltenango</option>
                        <option value="retalhuleu">Retalhuleu</option>
                        <option value="sacatepequez">Sacatepéquez</option>
                        <option value="sanMarcos">San Marcos</option>
                        <option value="santaRosa">Santa Rosa</option>
                        <option value="solola">Sololá</option>
                        <option value="suchitepequez">Suchitepéquez</option>
                        <option value="totonicapán">Totonicapán</option>
                        <option value="zacapa">Zacapa</option>
                    </select>
                <label className="block text-red-700 text-xs ml-4 h-5">
                    {
                      (validDep)===0 && <p>*El departmento ingresado es invalido*</p>
                    }
                </label>
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                    onClick={prevPage}
                >ANTERIOR</button>
                <button 
                    className="bg-unete-green-n text-unete-white-n text-xl pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                    onClick={registeUser}
                >SIGUIENTE</button>
            </div>
        </div>  
    );

    useEffect( () => {
        if (accountInfo.email) {
            if (accountInfo.email.length > 0) {
                const emailValido = new RegExp(`${process.env.REACT_APP_REGEX_EMAIL}`)
                if (emailValido.test(accountInfo.email)) {
                    setvalidEmail(1)
                } else {
                    setvalidEmail(0)
                }
            } else {
                setvalidEmail(0)
            }
        } else {
            setvalidEmail(0)
        }
        if(accountInfo.password){
            if(accountInfo.confirmPassword.length>0){
              if(accountInfo.password === accountInfo.confirmPassword){
                const strongPassword = new RegExp(`${process.env.REACT_APP_REGEX_PASSWORD}`)
                if (strongPassword.test(accountInfo.password)) {
                  setValidPassword(1)
                } else {
                  setValidPassword(0)
                }
              }else{
                setValidPassword(2)
              }
          }else{
            setValidPassword(0)
          }
          }else{
            setValidPassword(0)
          }

        if (accountInfo.dpi.length == 13) {
            setvalidDPI(1)
        } else {
            setvalidDPI(0)
        }

        if (accountInfo.phone.length == 8 || accountInfo.phone.length == 11) {
            setvalidTel(1)
        } else {
            setvalidTel(0)
        }
        
        console.log(accountInfo)
        if (accountInfo.department.toLowerCase() != 'ninguno') {
            setvalidDep(1)
        } else {
            setvalidDep(0)
        }


    },[accountInfo.department, validDep, accountInfo.dpi, validDPI, accountInfo.phone, validTel, accountInfo.email, validEmail, accountInfo.password, accountInfo.confirmPassword, validPassword]);

    let formPage = currentPage == 1 ? page2 : page1

    return (
        <div className="w-full px-3 sm:px-6 py-3">
            <div>
                <span className='text-unete-gray-n'>Ya tienes cuenta?</span> <span onClick={logIn} className="text-unete-blue underline">Iniciar sesión</span>
            </div>
            <div className="flex justify-center sm:justify-start">
                <label className="my-2 pt-3 pb-1 px-4 text-unete-blue rounded-custom uppercase font-bold text-lg sm:text-2xl">Crea tu cuenta</label>
            </div>
            
            <p className="text-lg text-unete-gray-n">Ingresa tus datos para que puedas realizar tus compras, ingresar números de cupón y verificar cupones ganadores</p>

            {formPage}
                  
        </div>
    );
}

export default SingUp;