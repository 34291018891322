import React from 'react';

//Assets
import iconPlus from '../../assets/img/icon-plus.png';
import q20 from '../../assets/img/q20.png';
import q40 from '../../assets/img/q40.png';
import q60 from '../../assets/img/q60.png';
import qplus from '../../assets/img/qplus.png';
import title1 from '../../assets/img/title1.png';


//Components
import BuyBtn from '../Button/Buy';
import RegisterBtn from '../Button/Register';

const Tickets = (props: any): JSX.Element => {

    const setNavigate = (navigate:string, select:string = 'options') => {
        props.navigate(navigate, select);
    }

    const setSelected = (type:string) => {
        props.onClickTicket(type);
    }

    // return <p></p>;
    return (
        <div className="mx-auto mt-12">
        <div className="flex justify-center">
            <div className="flex flex-col sm:flex-row">
                <div className="flex-auto">
                    
                </div>
            </div>
        </div>
            
        </div>
    );
}

export default Tickets;