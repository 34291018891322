import React from 'react';

//Assets
import iconTicketBlue from '../../assets/img/CTARegistrar.png';

//Components


const Register = (props: any): JSX.Element => {

    const onClickButton = () => {
        props.onClickBtn('registerCoupon','registerCoupon')
    }
    
    const size = props.size ? props.size : 'base';
    // return <p></p>;
    switch (size) {
        case 'full':
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}
                >
                    <div className="flex justify-center items-center">
                        <img src={iconTicketBlue} alt="" className="rounded-lg mr-2 h-24 inline-block"/>
                    </div>
                </button>
            );
        case 'sm':
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}
                >
                    <img src={iconTicketBlue} alt="" className="rounded-lg mr-2 h-16 inline-block"/>
                </button>
            );
        
        default:
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}    
                >
                    <img src={iconTicketBlue} alt="" className="rounded-lg mr-2 h-20 inline-block"/>
                </button>
            );
    }
}

export default Register;