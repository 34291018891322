import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import { toast } from 'react-toastify';
import axios from 'axios';

//Components
import Terms from '../Button/Terms';

const Start = (props: any): JSX.Element => {


    const setNavigate = (navigate:string, select:string = 'options') => {
        props.navigate(navigate, select);
    }

    const singUp = () => {
        props.navigate('sing-up')
    }


    useEffect( () => {
    });


    return (
    <div className="w-full py-3">
        <div className="max-w-sm mx-auto my-8 px-6 sm:px-0">
            <button
                onClick={() => setNavigate("login")}
                className="w-full h-16 mb-4 bg-unete-green-n text-unete-white-n rounded-20px leading-none focus:outline-none text-xl md:text-2xl"
            >
                INICIAR SESIÓN
            </button>

            <button
                onClick={() => setNavigate("anonymousRegister")}
                className="w-full h-16 mb-4 bg-unete-green-n text-unete-white-n rounded-20px leading-none focus:outline-none text-xl md:text-2xl"
            >
                CONTINUAR SIN REGISTRARSE
            </button>
            <br/>
            <br className='md:hidden'></br>
            <br className='md:hidden'></br>
            <div className='w-full text-xl' style={{position: 'fixed', left: '0', bottom: '0', textAlign: 'center', backgroundColor: '#0082C9', color: 'white'}}>
                TIP: Si quieres comprar más de un ticket <span onClick={singUp} className=' underline cursor-pointer' style={{color: '#FFE400'}}>crea un usuario.</span>
            </div>
        </div>      
    </div>
    );
}

export default Start;