import React from 'react';
import iconHeart from '../../assets/img/CTAComprar.png';

const Buy = (props: any): JSX.Element => {

    const onClickButton = () => {
        props.onClickBtn('selectTicket','selectTicket')
    }

    const size = props.size ? props.size : 'base';

    switch (size) {
        case 'full':
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}
                >
                    <div className="flex justify-center items-center">
                        <img src={iconHeart} alt="" className="rounded-lg mr-2 h-24 inline-block"/>
                    </div>
                </button>
            );
        case 'sm':
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}
                >
                    <img src={iconHeart} alt="" className="rounded-lg mr-2 h-16 inline-block"/>
                </button>
            );
        
        default:
            return (
                <button 
                    className="rounded-lg focus:outline-none"
                    onClick={onClickButton}
                >
                    <img src={iconHeart} alt="" className="rounded-lg mr-2 h-20 inline-block"/>
                </button>
            );
    }
}

export default Buy;
