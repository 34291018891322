import React from 'react';

//Assets
import iconTicket from '../../assets/img/icon-ticket-black.png';

//Components


const TicketWinner = (props: any): JSX.Element => {

    const onClickButton = () => {
        props.onClickBtn('consultWinner','')
    }

    const size = props.size ? props.size : 'base';

    switch (size) {
        case 'full':
            return (
                <button 
                    className="w-full h-16 mb-4 bg-unete-blue-n text-unete-white-n rounded-20px px-5 leading-none focus:outline-none"
                    onClick={onClickButton}
                >
                    <div className="flex justify-center items-center">
                        <img src={iconTicket} alt="" className="mr-4 h-6"/>
                        <span className="mt-2 font-semibold text-xl">Consultar cupón ganador</span>
                    </div>
                </button>
            );
        case 'sm':
            return (
                <button 
                    className="mx-3 h-10 inline-block mb-4 bg-unete-blue-n text-unete-white-n sm:flex items-center rounded-10px px-3 py-0 focus:outline-none text-xl"
                    onClick={onClickButton}
                >
                    <img src={iconTicket} alt="" className="mr-2 h-5 inline-block"/>
                    <span className="mt-1 text-sm">Consultar cupón ganador</span>
                </button>
            );
        
        default:
            return (
                <button 
                    className="mx-3 h-16 inline-block mb-4 bg-unete-blue-n text-unete-white-n sm:flex items-center rounded-15px px-5 focus:outline-none"
                    onClick={onClickButton}
                >
                    <img src={iconTicket} alt="" className="mr-4 h-6 inline-block"/>
                    <span className="mt-2">Consultar cupón ganador</span>
                </button>
            );
    }

}

export default TicketWinner;