import React from 'react';

//Assets
import bgCalendar from '../../assets/img/bg-calendar-svg.svg';
import title3 from '../../assets/img/title3.png';
import Historial from '../../assets/img/Historial.png';
import Fechas from '../../assets/img/Fechas.png';

//Components
import ViewBtn from '../Button/generic';

const Calendar = (props: any): JSX.Element => {

    return (
        <div className="xl:w-3/6 lg:w-4/6 sm:w-5/6 md:w-5/6 mx-auto">
            <div className="flex justify-center">
                <div className="flex flex-col sm:flex-row">
                    <div className="flex-auto">
                        <img src={title3} style={{ width: '300px' }} alt="title2" />
                    </div>
                </div>
            </div>
            <div className="mx-4 sm:mx-10 mb-6">
                <div className="w-full text-lg sm:text-2xl font-semibold border-2 border-unete-blue rounded-custom bg-cover bg-center" style={{ backgroundColor: '#0082C9' }}>
                    <img src={Fechas} alt="Fechas del calendario" className="w-full" />
                </div>
            </div>
        </div>
    );
}

export default Calendar;
