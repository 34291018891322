import React, { useState } from 'react';
import axios from 'axios';
import { Element } from 'react-scroll';

//Assets
import foto from '../../assets/img/Ayuvi/Foto2.png';
import { toast } from 'react-toastify';

//Components


const Contact = (props: any): JSX.Element => {

    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        message: "",
    });
    
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setContactInfo({ ...contactInfo, [name]: value });
    };
    
    const sendEmail = () => {
        if (contactInfo.name && contactInfo.message && contactInfo.email) {
            toast.info('Enviando mensaje...')
            axios({
                method: "post",
                url: `${process.env.REACT_APP_EMAIL_ENDPOINT}`,
                data: {
                toEmail: "info@ayuvi.org.gt",
                subject: "Formulario de contacto",
                body: `El usuario: <br/> <strong> - Nombre: <strong/> ${contactInfo.name}<br/> <strong> - Correo: <strong/>${contactInfo.email} <br/>Ha enviado el siguiente mensaje: <br/> ${contactInfo.message}`,
                },
                headers: {
                "x-api-key": `${process.env.REACT_APP_EMAIL_API_KEY}`,
                "Content-Type": "application/json",
                },
            })
            .then((response) => {
                toast.success('mensaje enviado con éxito.')
                setContactInfo({ name: "", email: "", message: "" });
            })
            .catch((error) => {
                toast.error('Error al enviar, intentalo más tarde.');
            });
        } else {
            toast.error('Es requerido llenar todos los datos del fomulario.');
        }
    };

    return (
        <>
        <Element name='contact' id='contact'>
        <div className="max-w-screen-xl mx-auto px-6 md:px-0 mt-12">
            <div className="flex-none md:flex">
                <div className="w-full md:w-3/5 mt-6">
                    <h3 className="uppercase text-unete-blue font-semibold text-xl ml-3">CONTÁCTANOS</h3>
                    <br/>
                    <div className="flex flex-wrap mb-0">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-unete-gray-n mb-1 ">NOMBRE:</label>
                            <input
                                name="name"
                                value={contactInfo.name}
                                onChange={handleChange}
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-0">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-unete-gray-n mb-1 ">CORREO ELECTRÓNICO:</label>
                            <input
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                type="text"
                                name="email"
                                value={contactInfo.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-0">
                        <div className="w-full px-3">
                            <label className="block tracking-wide text-unete-gray-n mb-1 ">MENSAJE:</label>
                            <textarea
                                name="message"
                                value={contactInfo.message}
                                onChange={handleChange}
                                rows={4}
                                className="appearance-none rounded-20px block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl resize-none"
                            />
                        </div>
                    </div>

                    <div className="w-full justify-start">
                        <button
                        onClick={sendEmail}
                        className="bg-unete-green-n text-unete-white-n text-xl font-medium pt-2 pb-1 px-12 rounded-10px ml-3 focus:outline-none"
                        >
                        ENVIAR
                        </button>
                    </div>

                </div>
                <br className='md:hidden'></br>
                <div className="w-full md:w-2/5 md:flex justify-center">
                    <div className="w-full md:flex justify-center px-10">
                        <img src={foto} alt="" className=" object-contain" />
                    </div>
                </div>
            </div>
        </div>
        </Element>

       
        </>
    );
}

export default Contact;