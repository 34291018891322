import React from "react";
import "./tailwind.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import HomePage from "./components/Pages/Home";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MNTFW85'
};
TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <BrowserRouter>
      <div className="font-body">
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          
          <Route exact path="/payment-result">
            {/* <Thanks /> */}
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}
