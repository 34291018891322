import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/cart-context';
import axios from 'axios';
import { toast } from 'react-toastify';
// import Cards from 'react-credit-cards';

//Assets
import bg from '../../assets/img/bg-room.png';
import bgTitle from '../../assets/img/btn-subtitle.png';

//Components
import Terms from '../Button/Terms';
import AnonymousSingUp from './AnonymousSingUp';

const Cards = require("react-credit-card-input");
const payment = require('payment');

const Checkout = (props: any): JSX.Element => {
    // Props: {navigate, values, quantity}

    const { userContext } = useUser();
    const [cardInfo, setCardInfo] = useState({
        cvv: '',
        expiry: '',
        focus: '',
        name: '',
        card: '',
        frequency: 'S',
        numberoOfPayments: 1,
        ticket: 'type1',
        expMonth: '',
        expYear: '',
        expDate: '',
        html: '',
        customValue: 0,
        isRecurrent: false,
    })

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setCardInfo({ ...cardInfo, [name]: value });
    };

    const handleInputFocus = (e:any) => {
        setCardInfo({...cardInfo, focus: e.target.name });
      }

    const ticketsValues: any = {
        type1: 20,
        type2: 40,
        type3: 60,
        type4: 80,
        type5: 100,
      };
    
    const clearInputs = () => {
        setCardInfo({
            cvv: '',
            expiry: '',
            focus: '',
            name: '',
            card: '',
            frequency: 'S',
            numberoOfPayments: 1,
            ticket: '',
            expMonth: '',
            expYear: '',
            expDate: '',
            html: '',
            customValue: 0,
            isRecurrent: false,
        });
    }

    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }

    const back = () => {
        clearInputs();
        setNavigate('selectTicket');
    }

    const pay = async () => {
        const {
            expDate,
            // frequency,
            // numberoOfPayments,
            cvv,
            card,
        } = cardInfo;

        const validateCVV = new RegExp(`${process.env.REACT_APP_REGEX_CVV}`)
        console.log('Regex CVV: ', validateCVV)
        if (validateCVV.test(cvv)) {
            const cardType = payment.fns.cardType(card);
            // console.log(expDate);
            if (
                cardType === "visa" ||
                cardType === "amex" ||
                cardType === "mastercard"
            ) {
                const cardData = {
                    cvv2: Number(cvv),
                    expDate: expDate.replace(" / ", ""),
                    cardNumber: Number(card.replace(/\s/g, "")),
                };
                //Si la card es valida comenzar a loguear
                // SLUG: uneteTest - uneteGT
                const transactionToken = await axios.post(
                    `${process.env.REACT_APP_MIGO_BASE_URL}/generateToken`,
                    // "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/generateToken",
                    { username: "yaluTech", password: "Y@luTech20$", authType: "password" }
                );
                //Encriptamos la tarjeta
                const encryptationRequest = await axios.post(
                    `${process.env.REACT_APP_MIGO_BASE_URL}/encrypyTest`,
                    // "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/encrypyTest",
                    {
                    toEncrypt: cardData,
                    }
                );
                //Extraemos la info
                const encryptedCard = encryptationRequest.data[0];
                const rawIv = encryptationRequest.data[1];
            
                //Preparamos headers
                const headers = {
                    "access-token": transactionToken.data.token,
                    "raw-iv": rawIv,
                    "Content-Type": "application/json",
                    // "Access-Control-Allow-Origin": "*",
                };
        
                let amount = cardInfo.customValue !== 0 ? cardInfo.customValue : ticketsValues[cardInfo.ticket];
            
                let formatedData = {
                    cardInfo: encryptedCard,
                    amount,
                    userId: userContext.id,
                    channel: "web",
                    redirectUrl: `https://unete.ayuvi.org.gt/?status=1&amount=${amount}`
                };
        
                if (cardType === "amex") {
                    formatedData.redirectUrl = '';
                }
        
                //La normal
                axios({
                    method: "post",
                    url:
                    cardType === "amex"
                        ? `${process.env.REACT_APP_MIGO_BASE_URL}/authorizeTransaction`
                        : `${process.env.REACT_APP_MIGO_BASE_URL}/authorize3DSTransaction`,
                    // cardType === "amex"
                    //     ? "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/authorizeTransaction"
                    //     : "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/authorize3DSTransaction",
                    headers,
                    data: formatedData,
                })
                    .then(async (response) => {
                    if (response.data.Authorize3DSResult) {
                        const {
                        HTMLFormData,
                        ResponseCode,
                        } = response.data.Authorize3DSResult;
                        //Hacer casaca del 3ds
                        if (ResponseCode) {
                        toast("Procesando pago");
            
                        //let tab = window.open("about:blank", "_blank");
                        window.document.write(HTMLFormData);
                        
                        //props.setNavigate("menu");
                        }
                    }
                    if (cardInfo.isRecurrent) {
                        await recurrentPayment(headers, formatedData);
                    }
            
                    if (response.data.ResponseCode === "1") {
                        toast.success("¡Transaccion exitosa!");
                        //Se registra el pago recurrente
                        setNavigate("final");
                    }
                    
                    if (response.data.ResponseCode === "2") {
                        toast.success("Su pago fue rechazado.");
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
            } else {
            toast.error("Solo se admiten tarjetas visa, master card y Amex");
            }
        } else {
            toast.error('Código CVV inválido.');
        }
    };

    const recurrentPayment = async (headers: any, formatedData: any) => {
        const recurrentRequest = await axios({
            method: "POST",
            headers,
            url: `${process.env.REACT_APP_MIGO_BASE_URL}/recurrent-payment`,
                // "https://api-staging.luka.com.gt/api/getHostedPage/uneteTest/recurrent-payment",
            data: {
                frecuency: cardInfo.frequency,
                nPayments: cardInfo.numberoOfPayments,
                ...formatedData,
            },
        });
        const { ReasonCode } = recurrentRequest.data;

        if (ReasonCode === "1") {
            toast.success("Pago recurrente realizado con exito");
            setNavigate("final");
        } else {
            toast.error("Pago rechazado");
        }
    };
    


    useEffect( () => {
        const total = props.values.customValue !== 0 ? props.values.customValue : ticketsValues[props.values.ticket];
        setCardInfo({...cardInfo, customValue: total})
    }, []);

    const userName = userContext.name ? userContext.name : '';

    return (
        <div className="w-full py-6 relative bg-no-repeat bg-center bg-cover" style={{backgroundImage:`url(${bg})`}}>
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>

            <div className="px-3 sm:px-20 py-3 text-unete-grey">
                <p className="mb-2 px-2 sm:px-0">Bienvenido (a) <strong className="text-unete-blue font-bold">{userName}</strong></p>
                <label className="my-2 py-2 px-4 uppercase font-semibold text-xl text-unete-blue bg-contain bg-center bg-no-repeat" style={{backgroundImage:`url(${bgTitle})`}}>Realiza tu compra</label>
                <p className="leading-none mt-3 text-base sm:text-lg px-2 sm:px-0">Cada ticket tiene una valor de Q20.00</p>
            
                
                <div className="sm:mx-20 py-3 sm:p-3 flex flex-col">
                    <p className="text-xl text-unete-grey text-center"> El valor de tu compra será de <br /> <b>Q{cardInfo.customValue}.00</b></p>

                    <div className="my-6">
                        <input 
                            className="w-full h-10 sm:w-104 py-2 px-4 mb-4 border-none focus:outline-none" 
                            type="text" 
                            name="name"
                            value={cardInfo.name}
                            onChange={handleChange}
                            placeholder="Nombre dueño tarjeta"
                        />

                        <div className=" h-16 flex align-top">
                            <Cards
                                cardNumberInputProps={{
                                    name: "card",
                                    value: cardInfo.card,
                                    onChange: handleChange,
                                }}
                                cardExpiryInputProps={{
                                    name: "expDate",
                                    value: cardInfo.expDate,
                                    onChange: handleChange,
                                }}
                                cardCVCInputProps={{
                                    name: "cvv",
                                    value: cardInfo.cvv,
                                    onChange: handleChange,
                                }}
                                // cvc={cardInfo.cvv}
                                // expiry={cardInfo.expiry}
                                // focused={cardInfo.focus}
                                // // name={cardInfo.name}
                                // number={cardInfo.card}
                                fieldClassName="w-full"
                                // onChange={handleChange}
                            />
                        </div>
                       
                    </div>
                    
                    <div className="flex justify-between">
                        <button 
                            className="bg-unete-blue text-unete-primary-yellow pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                            onClick={back}
                        >Anterior</button>
                        <button 
                            className="bg-unete-blue text-unete-primary-yellow pt-3 pb-2 px-10 rounded-20px font-bold focus:outline-none"
                            onClick={pay}
                        >Siguiente</button>
                    </div>
                </div>  

            </div>  
        </div>
    );
}

export default Checkout;