import React from 'react';
import { useUser } from '../../context/cart-context';

//Assets
import imgBg from '../../assets/img/bg-room.png';
import boy from '../../assets/img/bg-boy2.png';
import bgTitle from '../../assets/img/bg-ticket-btn.png';

//Components
import Buy from '../Button/Buy';
import Register from '../Button/Register';
import SocialButtons from '../Button/Social';
import Terms from '../Button/Terms';

const Congratulations = (props: any): JSX.Element => {
    const { userContext } = useUser();
    const url = 'https://www.ayuvi.org.gt/';

    const userName = userContext.name ? userContext.name : '';

    const copyText = async (e:any) => {
        e.preventDefault();
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(url);
        } else {
          return document.execCommand('copy', true, url);
        }
    }

    return (
        <div className=" bg-no-repeat py-6 px-3 bg-center relative w-full bg-cover">
            {/* <div className="hidden sm:flex w-5/12 bg-no-repeat absolute top-0 right-0 h-full bg-contain bg-right-bottom z-0" style={{backgroundImage:`url(${boy})`, backgroundPosition: 'rigth'}}></div> */}

            <div className="mx-2 w-full">
                <p className="mb-2 ml-3 text-unete-gray-n">Bienvenido (a) <strong className="text-unete-blue-n font-bold">{userName}</strong></p>
                <p className="text-unete-grey text-center">Tu(s) cupón(es) se ha(n) registrado de manera exitosa</p>
            </div>
            <div className="flex justify-start items-center">
                <div className=" w-full">
                   {/*  <div className="flex justify-center">
                        <label className="uppercase text-center text-unete-white-n font-bold text-4xl leading-none py-5 px-6 bg-contain bg-no-repeat bg-center" style={{backgroundImage:`url(${bgTitle})`}}>¡Felicidades!</label>
                    </div> */}
                    <p className="text-unete-blue text-center text-2xl font-bold my-8">
                    ¡FELICIDADES! Estás participando en los sorteos
                    <br></br>
                    semanales en donde rifaremos cada semana
                    <br></br>
                    10 premios de Q3,000.
                    </p>

                    <div className="flex flex-col sm:flex-row justify-center">
                        <div className='w-full sm:w-1/2'>                            
                            <div className="bg-no-repeat md:absolute top-0 left-0 ml-12 md:ml-0 -mt-12 md:mt-32 w-56 md:w-96 h-full bg-contain z-0">
                                <img src={boy} alt="Boy" />
                                <br></br>
                            </div>
                        </div>
                        <div className='w-full sm:w-1/2'>
                            <p className="text-unete-grey">
                            Gracias a tu aporte, un niño estará <br/>
                            recibiendo tratamiento integral sin costo. <br/> <br/>
                            Compártelo con tus familiares y amigos <br/>
                            para dar esperanza de vida a un niño <br/>
                            con cáncer.
                            </p>

                            <div className="my-8">
                                <SocialButtons />
                            </div>

                            <div className="flex flex-col sm:flex-row justify-center font-semibold my-2 tracking-tight leading-none">
                                <input
                                    className="appearance-none block w-full focus:outline-none bg-white text-unete-grey border border-unete-green-n py-3 px-4 mb-3 leading-tight text-xl"
                                    type="text"
                                    value={url}
                                    disabled
                                    style={{fontWeight: 200, borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px'}}
                                />
                                <button 
                                    className="text-unete-white-n text-xl bg-unete-blue-n font-bold focus:outline-none"
                                    style={{height: '51px', width:'120px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px'}}
                                    onClick={copyText}
                                >Copiar</button>
                            </div>

                            <div className="flex flex-col sm:flex-row justify-center font-semibold my-2 tracking-tight leading-none">
                                    <Buy size='sm' onClickBtn={props.onClickBtn}/>
                                    <Register size='sm' onClickBtn={props.onClickBtn}/>
                            </div>
                            <div className="text-unete-grey">
                                <Terms />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    );
}

export default Congratulations;