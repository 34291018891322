import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from "../../context/cart-context";
import Axios from "axios";
import { toast } from "react-toastify";

//Assets 
// import bgCalendar from '../../assets/img/bg-calendar-svg.svg';
import bgCalendar from '../../assets/img/bg-calendar.png';
import iconStar from '../../assets/img/icon-star.png';

//Components
import Buy from '../Button/Buy';
import Register from '../Button/Register';
import Terms from '../Button/Terms';



const UserTickets = (props: any): JSX.Element => {

    const { userContext } = useUser();
    const [tickets, setTickets] = useState<any>([]);

    const setNavigate = (navigate:string, select:string = '') => {
        props.navigate(navigate, select);
    }

    useEffect(() => {
        const getTicketsbyUser = async () => {
            Axios({
                method: "get",
                url: `${process.env.REACT_APP_TICKETS_BASE_URL}/tickets/user/${userContext.id}`,
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                if (response.data) {
                    setTickets(response.data);
                } else {
                    toast.info("No has comprando tickets");
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    toast.error("Error al ingresar los datos, intentar mas tarde");
                }
            });
        };
    
        getTicketsbyUser();
    }, [userContext]);

    const list = tickets.map((ticket:any, key:number) => {
        return (
            <div key={key} className="flex pb-2 ">
                <div className="flex-grow">
                    <div className="flex">
                        <div className="w-1/2 px-5">
                            <div className="text-unete-white-n pl-1 sm:pl-3 text-base sm:text-lg">
                                {ticket.id}
                            </div>
                        </div>
                        <div className="w-1/2 px-5">
                            <div className="text-unete-white-n pl-1 sm:pl-3 text-base sm:text-lg">
                                {ticket.code}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex-none w-16">
                    <div className="flex justify-center">
                        {tickets.status === 3 ? (
                            <img src={iconStar} alt="Ticket ganador" className="h-6 w-6" />
                        ) : (
                            <span className="h-5 w-5 bg-unete-grey rounded-full">&nbsp;</span>
                        )}
                    </div>
                </div> */}
            </div>
        );
    })

    const noTicketsData = (
        <div className="flex pb-2 ">
            <div className="flex-grow">
                <div className="flex">
                    <p className="px-1 sm:px-5 text-sm sm:text-xl text-white">No tienes tickets...</p>
                </div>
            </div>
            {/* <div className="flex-none w-16">
                <div className="flex justify-center">
                    <span className="h-5 w-5 bg-unete-grey rounded-full">&nbsp;</span>
                </div>
            </div> */}
        </div>
    );

    const tableTickets = tickets.length > 0 ? list : noTicketsData;
    const userName = userContext.name ? userContext.name : '';

    return (
        <div className="w-full py-6 relative">
            <div className="py-2 text-sm z-50 absolute bottom-0 w-full text-unete-grey">
                <Terms/>
            </div>

            <div className="px-3 sm:px-20 py-3 text-unete-grey">
                <p className="mb-2 px-2 sm:px-0">Bienvenido (a) <strong className="text-unete-blue font-bold">{userName}</strong></p>
                <p className="leading-none mb-5 mt-2 text-base sm:text-lg px-2 sm:px-0">Verifica si alguno de tus cupones es ganador</p>
            
                <div className="w-full sm:w-4/6 mx-auto my-3">
                    <div className="w-full text-lg sm:text-2xl font-semibold border-2 border-unete-green-n rounded-custom bg-repeat-y bg-contain" style={{backgroundColor:`#0082C9`}} >
                    <div className="flex px-4 mb-1">
                        <div className="flex-grow">
                            <div className="flex text-unete-yellow-n">
                                <div className="w-1/2 text-sm sm:text-lg">Número de ticket</div>
                                <div className="w-1/2 text-sm sm:text-lg">Código confirmación</div>
                            </div>
                        </div>
                        {/* <div className="flex-none w-16 text-sm sm:text-lg">Estado</div> */}
                    </div>
                    {/* <div className="border-2 border-unete-blue rounded-custom bg-table-tickets pt-2" style={{backgroundImage:`url(${bgCalendar})`}}> */}

                        {tableTickets}
                        
                    </div>
                </div>

                {/* <div className="w-full my-4 flex flex-row justify-center">
                    <div className="text-unete-secondary-yellow text-sm flex flex-row mr-2">
                        <img src={iconStar} alt="Icon star" className="h-4 mr-1" />
                        <span>Cupón ganador</span>
                    </div>
                    <div className="text-unete-grey text-sm flex flex-row ml-2">
                        <div className="h-4 w-4 bg-unete-grey rounded-full mr-1">&nbsp;</div>
                        <label>Cupón adquirido</label>
                    </div>
                </div> */}

                <div className="text-unete-grey-n text-sm mb-4 pt-0 px-2 sm:px-0">
                    <p className="font-semibold">NOTA:<br/>Si cuentas con un cupón ganador los pasos a seguir son los siguientes:</p>
                    <ul>
                        <li>- Los premios serán entregados en la ciudad de Guatemala.</li>
                        <li>- Para canjear los premios llama a los teléfonos de <strong>AYUVI: 2317-7882 / 2317-7885</strong>.</li>
                        <li>- Fundación Ayúdame a Vivir absorbe el 3% de impuesto de Timbre Fiscal y el 10% de ISR.</li>
                    </ul>
                </div>
                <div className="flex flex-col sm:flex-row justify-center text-2xl font-semibold my-3">
                <Buy onClickBtn={setNavigate}/>
                <Register onClickBtn={setNavigate}/>
                </div>
            </div>  
        </div>
    );
}

export default UserTickets;