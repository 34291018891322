import React from 'react';
import { Link, Element } from 'react-scroll';

//Assets
import rewards1 from '../../assets/img/Reward1.png';
import rewards2 from '../../assets/img/Reward2.png';
import rewards3 from '../../assets/img/Reward3.png';
import title2 from '../../assets/img/title2.png';
import Fondo from '../../assets/img/Ayuvi/Fondo_verde.png';
import Semanal from '../../assets/img/Semanal.png';
import sorteose from '../../assets/img/sorteosemanal.png';
import tesxtoSemanal from '../../assets/img/textosemanal.png';
import Final from '../../assets/img/tituloFinal.png';
import sorteoFinal from '../../assets/img/sorteoFinal.png';
import textoFinal from '../../assets/img/textoFinal.png';
import historial from '../../assets/img/Historial.png';



//Components
import BuyBtn from '../Button/Buy';
import RegisterBtn from '../Button/Register';
import ViewBtn from '../Button/generic';



const Rewards = (props: any): JSX.Element => {
    return (
        <Element name="rewards" id="rewards">
            <div className="flex justify-center items-center w-full" style={{ backgroundImage: `url(${Fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="flex flex-col justify-center items-center w-full h-full" >
       
                <img src={Semanal} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '25px'}}/>
                <img src={sorteose} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '5px'}}/>
                <img src={tesxtoSemanal} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '20px'}}/>
                <img src={Final} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '25px'}}/>
                <img src={textoFinal} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '5px'}}/>
                <img src={sorteoFinal} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '20px'}}/>
                <br />

                <ViewBtn name="HISTORIAL DE GANADORES" state="prizesHistory" onClickBtn={props.onClickBtn}/>
                <br />
        </div>
        </div>
        <br />
        <div className="max-w-screen-xl mx-auto">

            <div className="flex justify-center">
            <div className="flex flex-col sm:flex-row">
                <div className="flex-auto">
                    <img src={title2} style={{width: '350px'}} alt="title2" />
                </div>
            </div>
        </div>
            <div className=" md:flex mx-2 lg:mx-20 bg-unete-reward pb-10">

                <div className="w-full flex flex-col sm:flex-row uppercase text-unete-white-n font-bold pr-1 lg:pr-5 py-1 lg:py-4">
                    <div className="sm:w-2/3 px-4 lg:px-8 mt-3 block text-center sm:text-center" >
                        <div className="flex-auto">
                            <img src={rewards1} style={{maxHeight: '400px', maxWidth: '100%', minWidth: '200px'}} alt="title2" />
                        </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="sm:w-2/3 px-4 lg:px-8 mt-3 block text-center sm:text-center">
                        <div className="flex-auto">
                            <img src={rewards2} style={{maxHeight: '400px', maxWidth: '100%', minWidth: '200px'}} alt="title2" />
                        </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="sm:w-2/3 px-4 lg:px-8 mt-3 block text-center sm:text-center">
                        <div className="flex-auto">
                            <img src={rewards3} style={{maxHeight: '400px', maxWidth: '100%', minWidth: '200px'}} alt="title2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </Element>
    );
}

export default Rewards;