import React from 'react';
import unetFooterLogo from '../../assets/img/unete-footer.png';
import Marcas from '../../assets/img/Logos_Marcas.png';



const Footer = () => {
    return (
        <footer className="py-16">
            <img className="mx-auto h-12" src={Marcas} alt="Únete Ayuvi" /> 
        </footer>
    );
}

export default Footer;