import React from 'react';
import { Link, Element } from 'react-scroll';

//Assets
import Titulo from '../../assets/img/Ayuvi/Titulo.png';
import Pizarra from '../../assets/img/Ayuvi/Pizarron.png';
import Texto from '../../assets/img/Ayuvi/Info.png';
import Fondo from '../../assets/img/Ayuvi/Fondo_verde.png';
import TextoCifras from '../../assets/img/Ayuvi/Titulo_cifras.png';
import Incremento from '../../assets/img/Ayuvi/Incremento.png';
import Reduccion from '../../assets/img/Ayuvi/Reduccion.png';
import Texto2 from '../../assets/img/Ayuvi/Texto_2.png';


const Info = (props: any): JSX.Element => {
    return (
        <Element name="info" id="info">
    <div className="flex justify-center items-center w-full hidden sm:block" style={{ backgroundImage: `url(${Fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

        <div className="flex flex-col justify-center items-center w-full h-full" >
            <br />
            <img src={Titulo} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '50px'}}/>
            
            <div
                className="flex justify-center items-center"
                style={{
                    backgroundImage: `url(${Pizarra})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', // Añade esta propiedad para que la imagen se ajuste al div
                    width: '100vw', // Usa unidades relativas para el ancho y alto
                    height: '100vh',
                    margin: '0',
                    padding: '0', // Añade esto para eliminar cualquier margen o relleno predeterminado
                }}
                >
                <img
                    src={Texto}
                    alt="Texto"
                    className="w-auto h-auto max-w-full max-h-full"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
            </div>

                <img
                src={TextoCifras}
                alt="TextoCifras"
                className="w-auto h-auto max-w-full max-h-full"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />    
            <br />
                <div className="flex mb-4 justify-center items-center" style={{paddingTop: '50px'}}>
                <div className="mr-4">
                    <img
                        src={Incremento}
                        alt="Incremento"
                        className="w-auto h-auto max-w-full max-h-full"
                        style={{ maxWidth: '125%', maxHeight: '125%', paddingLeft: '50px', paddingRight: '50px'}}
                    />
            </div>
             <div>
                    <img
                        src={Reduccion}
                        alt="Reducción"
                        className="w-auto h-auto max-w-full max-h-full"
                        style={{ maxWidth: '125%', maxHeight: '125%',paddingLeft: '50px', paddingRight: '50px' }}
                    />
            </div>
        </div>
        <br />
        <img
        src={Texto2}
        alt="Texto2"
        className="w-auto h-auto max-w-full max-h-full"
        style={{ maxWidth: '100%', maxHeight: '100%' ,paddingTop: '50px', paddingBottom: '50px'}}
    />    
    <br style={{paddingTop: '50px'}}/>
    </div>
</div>
<div className="flex justify-center items-center w-full sm:hidden w-full" style={{ backgroundImage: `url(${Fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

    <div className="flex flex-col justify-center items-center w-full h-full" >
        <br />
        <img src={Titulo} alt="Titulo" className="w-126 mb-4" style={{paddingTop: '50px'}}/>
        
        <div
            className="flex justify-center items-center"
            style={{
                backgroundImage: `url(${Pizarra})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover', // Añade esta propiedad para que la imagen se ajuste al div
                width: '100vw', // Usa unidades relativas para el ancho y alto
                height: '100vh',
                margin: '0',
                padding: '0', // Añade esto para eliminar cualquier margen o relleno predeterminado
            }}
            >
            <img
                src={Texto}
                alt="Texto"
                className="w-auto h-auto max-w-full max-h-full"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
        </div>

            <img
            src={TextoCifras}
            alt="TextoCifras"
            className="w-auto h-auto max-w-full max-h-full"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
        />    
        <br />
            <div className="mb-4 justify-center items-center" style={{paddingTop: '20px'}}>
                <div className="mr-4">
                    <img
                        src={Incremento}
                        alt="Incremento"
                        className="w-auto h-auto max-w-full max-h-full"
                        style={{ maxWidth: '100%', maxHeight: '100%', paddingTop:'10px'}}
                    />
            </div>
            <div>
                    <img
                        src={Reduccion}
                        alt="Reducción"
                        className="w-auto h-auto max-w-full max-h-full"
                        style={{ maxWidth: '100%', maxHeight: '100%',paddingTop:'150px'  }}
                    />
                </div>
            </div>
        <br />
        <img
        src={Texto2}
        alt="Texto2"
        className="w-auto h-auto max-w-full max-h-full"
        style={{ maxWidth: '100%', maxHeight: '100%' ,paddingTop: '50px', paddingBottom: '50px'}}
        />    
        <br style={{paddingTop: '50px'}}/>
    </div>
</div>



        </Element>
    );
}

export default Info;