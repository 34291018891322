import React, { useEffect, useState } from "react";
import { Link, Element } from "react-scroll";
import { toast } from "react-toastify";
import { useUser } from "../../../context/cart-context";

//Assets
import logoBrand from '../../../assets/img/unete-logo.png';
import menulogo from '../../../assets/img/Menu.png';
import linea from '../../../assets/img/Linea.png';
import cuadroin from '../../../assets/img/Cuadroini.png';
import { url } from "inspector";
//import logo from '';


  
   

const Nav = (props: any): JSX.Element => {

    const [openMenu, setOpenMenu] = useState(false);
    const { userContext, setUserContext } = useUser();

    const onClickButton = (navigate:string, selection:string = '') => {
        props.onClickBtn(navigate,selection);
    }

    useEffect( () => {
    }, [userContext]);

    return (
        <nav className="w-full top-0 z-20 h-20 bg-white" style={{paddingTop: '10px'}}>
            <div className="max-w-screen-xl mx-10 sm:mx-20 xl:mx-auto flex justify-between items-center">
                <img src={logoBrand} alt="logo" className="h-10 ml-5" />
            <div className="w-full relative">
                <div className="w-full hidden lg:flex items-center justify-end leading-none xl:text-sm">
                    <Link 
                        to="info" 
                        smooth={true}
                        offset={-90}
                        duration={600}
                        className="flex items-center px-1 pt-2 pb-1 text-black rounded-md mr-5 xl:mr-10"
                        >
                        <div className="menu-item">
                            <span className="text-sm font-normal hover:text-blue-500">AYUVI</span>
                            <div className="line"></div>
                        </div>
                    </Link>

                    <Link 
                            to="#" 
                            className="flex items-center px-2 pt-2 pb-1 text-black hover:text-blue-500 rounded-md mr-5 xl:mr-10"
                            onClick={()=>{onClickButton('registerCoupon','registerCoupon')}}
                        >
                            <span>REGISTRAR TU CUPÓN Y PARTICIPA</span>
                    </Link>
                    <Link 
                            to="#" 
                            className="flex items-center px-2 pt-2 pb-1 text-black hover:text-blue-500 rounded-md mr-5 xl:mr-10"
                            onClick={()=>{onClickButton('consultWinner')}}
                        >
                            <span>DESCUBRE TU BOLETO</span>
                    </Link>
                    <Link 
                            to="contact" 
                            smooth={true}
                            offset={-90}
                            duration={600}  
                            className="flex items-center px-2 pt-2 pb-1 text-black hover:text-blue-500 rounded-md mr-5 xl:mr-10"
                        >
                            <span>CONTÁCTANOS</span>
                    </Link>
                    <Link 
                            to="#" 
                            smooth={true}
                            offset={-90}
                            duration={600}  
                            className="flex items-center px-2 pt-2 pb-1 hover:text-blue-500 rounded-md mr-5 xl:mr-10"
                            onClick={ () => {
                                setOpenMenu(false); 
                                onClickButton('login','options');
                            }}
                            
                        >
                            {Object.keys(userContext).length === 0 ? (
                            <div style={{
                                backgroundImage: `url(${cuadroin})`,
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center', 
                                padding: '10px', 
                                display: 'inline-block', 
                                }}>
                            <span>INICIAR SESIÓN</span>
                            </div>
                            ): ( <div></div> ) }
                    </Link>
                    <Link 
                            to="#"
                            className="flex items-center px-2 pt-2 pb-1 text-black hover:text-blue-500 rounded-md mr-5 xl:mr-10"
                            onClick={() =>!openMenu ? setOpenMenu(true) : setOpenMenu(false)}
                        >
                            <img src={menulogo} alt="menu" style={{height: 20}}/>
                        </Link>
                        
                    </div>

                    <div className="w-full flex items-center justify-end lg:hidden">
                        <Link 
                            to="#" 
                            className="flex items-center px-2 py-1 text-unete-yellow-n hover:text-unete-yellow-n hover:text-unete-white-n rounded-md focus:outline-none"
                            onClick={() =>!openMenu ? setOpenMenu(true) : setOpenMenu(false)}
                        >
                            <svg className="fill-current h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>MENÚ</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </Link>
                    </div>
                    
                    {openMenu && (
                        <div className="absolute bg-white z-20 w-56 lg:w-auto right-0 mt-2 mr-1 rounded-b-15px rounded-l-15px shadow-3xl">
                            <ul className="mx-6 my-4 text-unete-blue">
                            {Object.keys(userContext).length !== 0 ? (
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer" 
                                    onClick={ () => {
                                        setOpenMenu(false); 
                                        onClickButton('options','options');
                                    }}
                                >MI PERFIL</li>
                            ) : ( null )}

                            {Object.keys(userContext).length === 0 ? (
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer" 
                                    onClick={ () => {
                                        setOpenMenu(false); 
                                        onClickButton('login','options');
                                    }}
                                >INICIAR SESIÓN</li>
                            ) : (
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer" 
                                    onClick={ () => {
                                        setOpenMenu(false); 
                                        setUserContext('');
                                        localStorage.removeItem('user');
                                        toast.info('Se ha cerrado la sesión.');
                                    }}
                                >CERRAR SESIÓN</li>
                            )}

                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer"
                                    onClick={ () => {setOpenMenu(false); onClickButton('selectTicket')}}
                                >COMPRAR</li>
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer"
                                    onClick={ () => {setOpenMenu(false); onClickButton('registerCoupon','registerCoupon')}}
                                >REGISTRAR CUPÓN</li>
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer flex">
                                    <Link
                                        to="rewards"
                                        smooth={true}
                                        offset={-90}
                                        duration={600}  
                                        className="w-full"
                                        onClick={ () => {setOpenMenu(false)}}
                                    >PREMIOS Y GANADORES</Link>
                                </li>
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer"
                                    onClick={ () => {setOpenMenu(false); onClickButton('consultWinner')}}
                                >CONSULTAR CUPÓN GANADOR</li>
                                {/*<li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer"
                                    onClick={ () => {setOpenMenu(false); onClickButton('')}}
                                >Comentarios</li>*/}
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer flex">
                                    <Link
                                        to="info"
                                        smooth={true}
                                        offset={-90}
                                        duration={600}  
                                        className="w-full"
                                        onClick={ () => {setOpenMenu(false)}}
                                    >NUESTRA HISTORIA</Link>
                                </li>
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer"
                                    onClick={ () => {setOpenMenu(false); onClickButton('passwordRecovery')}}
                                >RECUPERAR CONTRASEÑA</li>
                                <li className="px-2 pt-1 text-unete-blue hover:text-unete-primary-yellow hover:bg-unete-blue rounded-md cursor-pointer flex">
                                    <Link
                                        to="contact"
                                        smooth={true}
                                        offset={-90}
                                        duration={600}  
                                        onClick={ () => {setOpenMenu(false)}}
                                    >CONTÁCTANOS</Link>
                                </li>
                            </ul>
                        </div>
                    )}



                </div>
            </div>
        </nav>

    );
}

export default Nav;